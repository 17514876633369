import Header from "../Header";

const CreateUser = () => {
    return (
        <div>
            <Header />
            <div className="mx-6 lg:mx-20 mt-16 lg:mt-24">
                <h1 className="text-center font-bold text-xl">
                    Create New User
                </h1>
            </div>
        </div>
    );
}
 
export default CreateUser;