const FormInput = ({ label, type, onInput, value, disabled }) => {
    return (
        <div className="flex flex-col space-y-1">
            <label className="login-input-label">{label}</label>
            <input type={type} placeholder={label} className="login-input" onInput={onInput} value={value} disabled={disabled}/>
        </div>
    );
}

FormInput.defautlProps = {
    label: 'label goes here',
    type: 'text',
    placeholder: 'Placeholder goes here'
}
 
export default FormInput;