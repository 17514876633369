import { LocationMarkerIcon } from '@heroicons/react/solid';
import {Link} from 'react-router-dom';
import {getDate, getDesignation, tryGetProperty} from '../../utils';

const PropertyPreview = ({property}) => {
    return (
        <div className="bg-white border-2 border-transparent hover:border-blue-700 shadow hover:shadow-xl space-y-1 rounded-md select-none transition duration-300 ease-out">
            <div className="text-sm space-y-2 p-4">
                <h3 className="text-center text-lg font-bold text-primary">
                    {getDesignation(property.designation)}
                </h3>

                <div className="space-y-2 text-sm">
                    <div className="grid grid-cols-2 gap-2">
                        <div className="space-y-1">
                            <div className="flex items-center space-x-2">
                                <h3 className="font-medium">Número:</h3>
                                <h3 >{tryGetProperty(property.number)}</h3>
                            </div>

                            <div className="flex items-center space-x-2">
                                <h3 className="font-medium">Quarteirão:</h3>
                                <h3 >{tryGetProperty(property.block)}</h3>
                            </div>

                            <div className="flex items-center space-x-2">
                                <h3 className="font-medium">Bairro:</h3>
                                <h3 >{tryGetProperty(property.neighborhood)}</h3>
                            </div>

                            <div className="flex items-center space-x-2">
                                <h3 className="font-medium">Distrito:</h3>
                                <h3 >{tryGetProperty(property.district)}</h3>
                            </div>

                            <div className="flex items-center space-x-2">
                                <h3 className="font-medium">Província:</h3>
                                <h3 >{property.province}</h3>
                            </div>
                        </div>

                        <div className="space-y-1">
                            <div className="flex items-center space-x-2">
                                <h3 className="font-medium">Tipo:</h3>
                                <h3 >{tryGetProperty(property.property_type)}</h3>
                            </div>

                            <div className="flex items-center space-x-2">
                                <h3 className="font-medium">Estado:</h3>
                                <h3 >{tryGetProperty(property.living_state)}</h3>
                            </div>

                            <div className="flex items-center space-x-2">
                                <h3 className="font-medium">Finalidade:</h3>
                                <h3 >{tryGetProperty(property.property_function)}</h3>
                            </div>
                        </div>
                    </div>

                    <div className="flex items-center space-x-2">
                        <LocationMarkerIcon className="W-4 h-4" />
                        <h3 >{tryGetProperty(property.avenue)},</h3>
                        <h3 >{tryGetProperty(property.street)}</h3>
                    </div>
                </div>
            </div>

            <div className="flex justify-between items-center px-4 py-2 border-t border-gray-200 text-xs">
                <div className="flex items-center space-x-1">
                    <h3 className="font-medium">Registada:</h3>
                    <span className="font-light">{getDate(property.createdAt)}</span>
                </div>

                <Link to={`/properties/details/${property._id}`} className="inline-block rounded px-8 py-1.5 bg-primary hover:bg-blue-700 text-white text-xs font-medium">
                    Ver
                </Link>
            </div>
        </div>
    );
}

export default PropertyPreview;
