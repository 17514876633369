import Header from "../../Header";
import { EmojiSadIcon } from '@heroicons/react/solid';
import MemberPreview from "../MemberPreview";
import { Link, useHistory, useParams } from "react-router-dom";
import useFetch from "../../useFetch";
import PropertyAttribute from "../PropertyAttribute";
import { BASE_URL } from '../../../utils';

const RentedPropertyDetails = () => {
    const { id } = useParams();
    const { isLoading, error, data: property } = useFetch(`${BASE_URL}/api/properties/${id}`);

    // Goto rented-property update page
    const history = useHistory();
    const handleUpdate = (propertyId) => {
        history.push(`/rented-properties/update/${propertyId}`);
    };

    return (
        <div>
            <Header />
            <div className="mx-20 mt-24">
                { isLoading && <div className="font-medium">Carregando os dados...</div> }
                { error && <div className="text-red-500">Houve um erro no sistema</div> }
                {
                    property &&
                    <div className="w-1/2 mx-auto bg-white shadow-md rounded-md space-y-2">
                        {/* Property Information */}
                        <div className="space-y-4">
                            <div className="px-8 py-4 border-b-2 border-gray-200 text-center space-y-2">
                                <h1 className="text-xl font-extrabold text-primary">
                                    Propriedade Sub-Arrendada:
                                </h1>
                                {
                                    property.member_of &&
                                    <h2 className="text-lg space-x-2">
                                        <span>Arrendada em:</span>
                                        <Link to={`/properties/details/${ property.member_of._id }`} target="_blank" className="text-indigo-700 hover:underline">
                                            {
                                                property.member_of.designation ?
                                                <span title="Ver detalhes">{ property.member_of.designation }</span> :
                                                <span>Ver Detalhes</span>
                                            }
                                        </Link>
                                    </h2>
                                }
                                {
                                    !property.member_of &&
                                    <h2 className="text-red-600 italic flex items-center space-x-1">
                                        <EmojiSadIcon className="w-6 h-6" />
                                        <span>Esta renda não faz ainda parte de nenhuma propriedade.</span>
                                    </h2>
                                }
                            </div>
                            <div className="space-y-3 px-6">
                                { property.designation && <PropertyAttribute title="Designação" attribute={property.designation} /> }
                                { property.building && <PropertyAttribute title="Edifício" attribute={property.building} /> }
                                { property.floor && <PropertyAttribute title="Andar" attribute={property.floor} /> }    
                                { property.door && <PropertyAttribute title="Porta" attribute={property.door} /> }
                                { property.property_type && <PropertyAttribute title="Tipo de Propriedade" attribute={property.property_type} /> }
                                { property.living_state && <PropertyAttribute title="Estado" attribute={property.living_state} /> }
                                { property.property_function && <PropertyAttribute title="Finalidade" attribute={property.property_function} /> }
                                { property.observation && <PropertyAttribute title="Observação" attribute={property.observation} /> }
                            </div>
                        </div>
                        {/* Members of the property */}
                        <div className="space-y-4">
                            <h2 className="px-8 py-4 border-b-2 border-t-2 border-gray-200 text-center text-lg font-extrabold text-primary">
                                Membros da Propriedade
                            </h2>
                            <div className="px-6 text-gray-800 space-y-5">
                                { property.owners.length != 0  && <MemberPreview title='Proprietários' members={property.owners} /> }
                                { property.keepers.length != 0 && <MemberPreview title='Responsáveis' members={property.keepers} /> }
                                { property.members.length != 0 && <MemberPreview title='Membros' members={property.members} /> }
                            </div>
                        </div>
                        {/* Submit buttons */}
                        <div className="border-t-2 py-4 border-gray-200 flex justify-center items-center space-x-4">
                            <button className="btn btn-primary" onClick={() => handleUpdate(property._id)}>
                                Actualizar
                            </button>
                        </div>
                    </div>
                }
            </div>
        </div>
    );
}
 
export default RentedPropertyDetails;
