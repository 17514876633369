import {Link} from "react-router-dom";
import {getDate} from "../../utils";

const CitizenPreview = ({citizen}) => {
    return (
        <div className="bg-white border-2 border-transparent hover:border-blue-700 shadow hover:shadow-xl space-y-1 rounded-md select-none transition duration-300 ease-out">
            <div className="text-gray-900 space-y-3 p-4">
                <h3 className="text-center font-bold text-primary">
                    {`${citizen.first_name} ${citizen.last_name}`}
                </h3>
                <div>
                    <div className="flex space-x-3">
                        <h3 className="font-medium">BI:</h3>
                        <h4 className="italic">
                            {citizen.national_identification ? citizen.national_identification : 'Não definido'}
                        </h4>
                    </div>
                    <div className="flex space-x-3">
                        <h3 className="font-medium">Nascido(a):</h3>
                        <h4 className="italic">{getDate(citizen.birth_date)}</h4>
                    </div>
                    <div className="flex space-x-3">
                        <h3 className="font-medium">Proveniência:</h3>
                        <h4 className="italic">
                            {citizen.provenance ? citizen.provenance : 'Não definido'}
                        </h4>
                    </div>
                </div>
            </div>
            <div className="w-full flex justify-end items-center p-2 border-t border-gray-200">
                <Link to={`/citizens/details/${citizen._id}`} className="inline-block rounded px-8 py-1.5 bg-primary hover:bg-blue-700 text-white text-xs font-medium">
                    Ver
                </Link>
            </div>
        </div>
    );
}

export default CitizenPreview;
