import {ChevronDownIcon, LoginIcon} from "@heroicons/react/solid"
import {useState} from "react";
import {Link} from "react-router-dom";
import logo from "../inscrito-logo.svg";

const Header = () => {
    const [menuIsOpen, setMenuIsOpen] = useState(false);

    function handleLogOut(event) {
        // TODO: implement a logout functionality
    }

    return (
        <div className="fixed top-0 w-full h-14 bg-white border-b border-gray-300 flex justify-center z-50 ">
            <div className="w-full mx-20 flex justify-between items-center">
                {/* HEADER LOGO */}
                <div className="flex items-center space-x-4 text-primary">
                    <img src={logo} className="w-10" />
                    <h1 className="font-bold text-4xl">inscrito</h1>
                </div>

                {/* HEADER MENU */}
                <div className="flex items-center justify-between space-x-8">
                    <div className="relative inline-block text-left">
                        <button onClick={() => setMenuIsOpen(!menuIsOpen)} type="button" className="inline-flex rounded justify-center w-full shadow-sm px-6 py-2 bg-primary text-base font-medium text-white hover:bg-red-4000 focus:outline-none" id="menu-button" aria-expanded="true" aria-haspopup="true">
                            <span>Menu</span>
                            <ChevronDownIcon className="-mr-1 ml-2 h-5 w-5" />
                        </button>
                        {
                            menuIsOpen &&
                            <div className="origin-top-right absolute right-0 mt-2 rounded w-56 shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none" role="menu" aria-orientation="vertical" aria-labelledby="menu-button" tabIndex="-1">
                                <div className="divide-y divide-gray-200" role="none">
                                    {/* ROUTE LINKS */}
                                    <div>
                                        <Link to="/dashboard" className="bg-transparent hover:bg-blue-400 font-medium text-gray-700 hover:text-white text-left block w-full px-4 py-2" role="menuitem" tabIndex="-1" id="menu-item-0">
                                            Estatísticas
                                        </Link>
                                        <Link to="/properties" className="bg-transparent hover:bg-blue-400 font-medium text-gray-700 hover:text-white text-left block w-full px-4 py-2" role="menuitem" tabIndex="-1" id="menu-item-0">
                                            Todas Propriedades
                                        </Link>
                                        <Link to="/properties/create" className="bg-transparent hover:bg-blue-400 font-medium text-gray-700 hover:text-white text-left block w-full px-4 py-2" role="menuitem" tabIndex="-1" id="menu-item-0">
                                            Registar Propriedade
                                        </Link>
                                        <Link to="/rented-properties/create" className="bg-transparent hover:bg-blue-400 font-medium text-gray-700 hover:text-white text-left block w-full px-4 py-2" role="menuitem" tabIndex="-1" id="menu-item-0">
                                            Registar Renda
                                        </Link>
                                        <Link to="/citizens" className="bg-transparent hover:bg-blue-400 font-medium text-gray-700 hover:text-white text-left block w-full px-4 py-2" role="menuitem" tabIndex="-1" id="menu-item-0">
                                            Todos Cidadãos
                                        </Link>
                                        <Link to="/citizens/create" className="bg-transparent hover:bg-blue-400 font-medium text-gray-700 hover:text-white text-left block w-full px-4 py-2" role="menuitem" tabIndex="-1" id="menu-item-0">
                                            Registar Cidadão
                                        </Link>
                                    </div>

                                    {/* SIGN OUT BUTTON */}
                                    <button onClick={handleLogOut} type="button" className="flex items-center space-x-3 bg-transparent hover:bg-red-400 text-gray-700 hover:text-white w-full text-left px-4 py-2 text-base" role="menuitem" tabIndex="-1" id="menu-item-3">
                                        <LoginIcon className="w-6 h-6" />
                                        <span>Terminar Sessão</span>
                                    </button>
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Header;
