// const BASE_URL = 'http://localhost:5000';
const BASE_URL = '';

function getDate(date) {
    const createdAt = String(date).substring(0, String(date).indexOf('T'));
    const [year, month, day] = createdAt.split('-');
    return `${day} de ${getMonth(month)} de ${year}`;

    function getMonth(month) {
        switch (Number(month)) {
            case 1:
                return 'Janeiro';
            case 2:
                return 'Fevereiro';
            case 3:
                return 'Março';
            case 4:
                return 'Abril';
            case 5:
                return 'Maio';
            case 6:
                return 'Junho';
            case 7:
                return 'Julho';
            case 8:
                return 'Agosto';
            case 9:
                return 'Setembro';
            case 10:
                return 'Outubro';
            case 11:
                return 'Novembro';
            case 12:
                return 'Dezembro';
        }
    }
}

function getDesignation(designation) {
    if (designation && designation.length > 30)
        return designation.substring(0, 30);
    else if (designation)
        return designation;
    return 'Sem Designação';
}

function tryGetProperty(property) {
    return property ? property : 'Não definido';
}

export {
    BASE_URL,
    getDate,
    getDesignation,
    tryGetProperty
};
