import useFetch from '../useFetch';
import Header from "../Header";
import PropertyPreview from "./PropertyPreview";
import {BASE_URL} from '../../utils';
import {useState} from "react";

const Properties = () => {
    const {isLoading, error, data: properties} = useFetch(`${BASE_URL}/api/properties/all`);

    // Property search states
    const [propertyToSearch, setPropertyToSearch] = useState('');
    const [propertySearchError, setPropertySearchError] = useState(false);
    const [searchedProperties, setSearchedProperties] = useState([]);

    function handleSearchProperty(event) {
        event.preventDefault();

        fetch(`${BASE_URL}/api/properties/search/main/${propertyToSearch}`)
            .then(response => {
                if (!response.ok)
                    throw new Error();
                return response.json();
            })
            .then(result => {
                if (result.length > 0) {
                    setPropertySearchError(false);
                    setSearchedProperties(result);
                    console.log(result);
                } else {
                    throw new Error();
                }
            })
            .catch(_error => {
                setSearchedProperties([]);
                setPropertySearchError(true);
            });
    }

    return (
        <>
            <Header />
            <div className="mx-20 mt-24 space-y-6">
                <div className="flex justify-between items-center border-b border-gray-200 pb-4 ">
                    <h1 className="font-bold text-3xl text-gray-900">Propriedades Registradas</h1>

                    <form onSubmit={handleSearchProperty} className="w-1/2">
                        <input value={propertyToSearch} onInput={e => setPropertyToSearch(e.target.value)} type="search" placeholder="Pesquise aqui" className="w-full p-2 rounded border-2 border-gray-300 hover:border-blue-700 focus:border-blue-700 placeholder-gray-700 text-black transition duration-300 ease-out focus:outline-none" />
                    </form>
                </div>

                {error && <div className="text-2xl font-bold text-red-500">Houve um erro no sistema...</div>}

                {isLoading && <div className="text-2xl font-bold font-medium">Carregando os dados...</div>}

                {
                    properties && searchedProperties.length === 0 &&
                    <div className="grid grid-cols-3 gap-6">
                        {properties.map(property => <PropertyPreview property={property} key={property._id} />)}
                    </div>
                }

                {
                    searchedProperties.length >= 0 &&
                    <div className="grid grid-cols-3 gap-6">
                        {searchedProperties.map(property => <PropertyPreview property={property} key={property._id} />)}
                    </div>
                }

                {propertySearchError && <div className="text-2xl font-bold text-red-500">Nehum resultado encontrado...</div>}
            </div>
        </>
    );
}

export default Properties;
