import Header from "../Header";

const Users = () => {
    return (
        <div>
            <Header />
            <div className="mx-6 lg:mx-20 mt-16 lg:mt-24">
                {/* NAME OF THE PAGE */}
                <div className="flex justify-between items-center border-b border-gray-200 pb-4 ">
                    <h1 className="font-bold text-3xl text-gray-900">Usuarios</h1>
                </div>

                {/* LIST ALL USERS OF THE SYSTEM */}
                <div>
                </div>
            </div>
        </div>
    );
}

export default Users;
