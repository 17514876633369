import useFetch from '../../useFetch';
import { LocationMarkerIcon } from '@heroicons/react/solid';
import { Link } from 'react-router-dom';
import { BASE_URL } from '../../../utils';

const RentedPropertyPreview = ({ property }) => {
    const { isLoading, error, data: belongingProperty } = useFetch(BASE_URL + `/api/properties/${property.member_of}`);

    return (
        <div className="bg-white border-2 border-transparent hover:border-blue-300 shadow hover:shadow-lg space-y-1 rounded-md select-none transition duration-300 ease-out">
            <div className="text-gray-600 text-sm space-y-2 p-4">
                <h3 className="text-center text-lg font-bold text-primary">
                    { property.designation }
                </h3>
                <div className="space-y-2 text-sm">
                    <div className="grid grid-cols-2 gap-2">
                        <div className="space-y-1">
                            <div className="space-x-2">
                                <span className="font-medium">Pertence a propriedade:</span>
                                <span className="italic">
                                    { belongingProperty.designation ? belongingProperty.designation : 'Sem designacao'}
                                </span>
                            </div> 
                            <div className="space-x-2">
                                <span className="font-medium">Quarteirao:</span>
                                <span className="italic">
                                </span>
                            </div> 
                            <div className="space-x-2">
                                <span className="font-medium">Bairro:</span>
                                <span className="italic">{ property.neighborhood }</span>
                            </div> 
                            <div className="space-x-2">
                                <span className="font-medium">Distrito:</span>
                                <span className="italic">{ property.district }</span>
                            </div> 
                            <div className="space-x-2">
                                <span className="font-medium">Provincia:</span>
                                <span className="italic">{ property.province }</span>
                            </div>
                        </div>
                        <div className="space-y-1">
                            <div className="space-x-2">
                                <span className="font-medium">Tipo:</span>
                                <span className="italic">{ property.property_type }</span>
                            </div> 
                            <div className="space-x-2">
                                <span className="font-medium">Estado:</span>
                                <span className="italic">{ property.living_state }</span>
                            </div> 
                            <div className="space-x-2">
                                <span className="font-medium">Finalidade:</span>
                                <span className="italic">{ property.property_function }</span>
                            </div>
                        </div>
                    </div>
                    <p className="flex items-center space-x-2">
                        <LocationMarkerIcon className="w-4 h-4" />
                        <span>{ `${property.avenue} ${property.street}` }</span>
                    </p>
                </div>
            </div>
            <div className="w-full flex justify-between items-center px-4 py-2 border-t border-gray-200 text-xs space-x-2">
                <div className="flex items-center space-x-2">
                    <span className="font-medium">Registrada:</span>
                    <span className="font-light">{ property.createdAt }</span>
                </div>
                <Link to={`/properties/details/${property._id}`} className="inline-block rounded px-8 py-1.5 bg-primary hover:bg-blue-700 text-white text-xs font-medium">
                    Ver
                </Link>
            </div>
        </div>
    );
}
 
export default RentedPropertyPreview;
