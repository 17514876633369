import {useHistory, Link} from 'react-router-dom';
import FormInput from '../../login/FormInput';
import Header from "../../Header";
import {useState} from 'react';
import {ArrowRightIcon, XIcon, HomeIcon, BanIcon} from '@heroicons/react/solid';
import {BASE_URL} from '../../../utils';

const CreateRentedProperty = () => {
    const history = useHistory();
    const [designation, setDesignation] = useState('');
    const [building, setBuilding] = useState('');
    const [floor, setFloor] = useState('');
    const [door, setDoor] = useState('');
    const propertyType = 'Sub Arrendada';
    const [livingState, setLivingState] = useState('Habitada');
    const [propertyFunction, setPropertyFunction] = useState('');
    const [observation, setObservation] = useState('');

    // Members of the property
    const [propertyMembers, setPropertyMembers] = useState([]);
    const [citizens, setCitizens] = useState([]);

    function handleAddCitizen(e) {
        e.preventDefault();

        const newMember = {
            name: e.target.dataset.name,
            type: e.target.dataset.type,
            id: e.target.dataset.id
        };

        // Show members on UI (avoiding duplicates)
        const newMembers = propertyMembers.filter(propertyMember =>
            propertyMember.id !== newMember.id);
        setPropertyMembers([...newMembers, newMember]);

        // Add and prepare members to be added to the database (avoiding duplicates)
        const newCitizens = citizens.filter(citizen =>
            citizen.id !== newMember.id);
        setCitizens([...newCitizens, newMember]);
    }

    function handleRemoveCitizen(citizenId) {
        // Remove from UI
        if (propertyMembers) {
            const newMembers = propertyMembers.filter(member =>
                member.id !== citizenId);
            setPropertyMembers([...newMembers]);
        }

        // Remove from list of members to be saved on database
        if (citizens) {
            const newCitizens = citizens.filter(citizen =>
                citizen.id !== citizenId);
            setCitizens([...newCitizens]);
        }
    }

    function handleOwnership(e) {
        const newType = e.target.value;

        const newCitizens = citizens;
        newCitizens.forEach(citizen => {
            if (citizen.id === e.target.dataset.id) {
                citizen.type = newType;
            }
        });

        setCitizens([...newCitizens]);
    }

    // States form the submission of the form
    const [formSubmited, setFormSubmited] = useState(false);
    const [submissionError, setFormSubmissionError] = useState(false);
    const [processingSubmission, setProcessingSubmission] = useState(false);

    // Once the form is submited successfully this state will contain
    // the ID of the new property saved on DB, in order to redirect
    // to the property details page.
    const [newPropertyID, setNewPropertyID] = useState(null);

    function handleSubmit(e) {
        // Form was submitted
        setFormSubmited(true);
        e.preventDefault();

        // Get all owner IDs
        const ownerIDs = citizens
            .filter(citizen => citizen.type === 'Proprietário')
            .map(owner => owner.id);

        // Get all keeper IDs
        const keeeprIDs = citizens
            .filter(citizen => citizen.type === 'Responsável')
            .map(keeper => keeper.id);

        // Get all member IDs
        const memberIDs = citizens
            .filter(citizen => citizen.type === 'Membro')
            .map(member => member.id);

        const property = {
            property_details: {
                designation: designation,
                building: building,
                floor: floor,
                door: door,
                property_type: propertyType,
                living_state: livingState,
                property_function: propertyFunction,
                observation: observation
            },
            property_owners: [...ownerIDs],
            property_keepers: [...keeeprIDs],
            property_members: [...memberIDs]
        };

        fetch(BASE_URL + "/api/properties", {
            headers: {'Content-Type': 'application/json'},
            method: 'POST',
            body: JSON.stringify(property)
        })
            .then(res => {
                if (!res.ok) {
                    throw Error();
                }

                return res.json();
            })
            .then(newProperty => {
                setFormSubmissionError(false);
                setProcessingSubmission(false);

                // Get new registered property ID from database
                // to redirect to property details page.
                // This first show a success message
                setNewPropertyID(newProperty._id);
            })
            .catch(err => setFormSubmissionError(true));
    }

    function handleClear() {
        setDesignation('');
        setBuilding('');
        setFloor('');
        setDoor('');
        setLivingState('Habitada');
        setPropertyFunction('');
        setObservation('');
        setPropertyMembers([]);
        setCitizens([]);
    }

    // Search state
    const [searchItem, setSearchItem] = useState('');
    const [searchItems, setSearchItems] = useState([]);
    const [searchError, setSearchError] = useState(false);

    function handleSearch(e) {
        e.preventDefault();

        fetch(`${BASE_URL}/api/citizens/search/${searchItem}`)
            .then(res => {
                if (!res.ok) {
                    throw new Error();
                }

                return res.json();
            })
            .then(items => {
                if (items.length > 0) {
                    setSearchError(false);
                    setSearchItems([...items]);
                } else {
                    throw new Error();
                }
            })
            .catch(_ => {
                setSearchItems([]);
                setSearchError(true);
            });
    }

    return (
        <div>
            <Header />
            <div className="mx-20 mt-24 pb-16">
                {
                    // Show a processing message
                    processingSubmission &&
                    <div className="p-8 space-y-4 w-1/2 mx-auto flex flex-col justify-center items-center bg-white shadow-md hover:shadow-lg rounded-md">
                        <h2 className="text-3xl font-extrabold text-green-500">
                            Processando o registro...
                        </h2>
                    </div>
                }
                {
                    // Show an error if the form submission had an error
                    submissionError &&
                    <div className="p-8 space-y-4 w-1/2 mx-auto flex flex-col justify-center items-center bg-white shadow-md hover:shadow-lg rounded-md">
                        <div className="text-center space-y-5">
                            <h2 className="text-3xl font-bold text-red-500">
                                Occorreu um erro no registro
                            </h2>
                            <p className="text-lg font-medium">
                                Por favor, tente novamente clicando no botao abaixo
                            </p>
                        </div>
                        <button className="btn btn-primary" onClick={() => {setProcessingSubmission(false); setFormSubmissionError(false); setFormSubmited(false)}}>
                            Tentar Novamente
                        </button>
                    </div>
                }
                {
                    // Show a success message
                    newPropertyID &&
                    <div className="p-8 space-y-4 w-1/2 mx-auto flex flex-col justify-center items-center bg-white shadow-md hover:shadow-lg rounded-md">
                        <div className="text-center space-y-5">
                            <h2 className="text-3xl font-bold text-green-500">
                                Propriedade registrada com sucesso
                            </h2>
                            <p className="text-lg font-medium">
                                Veja os dados e informações da propriedade
                            </p>
                        </div>
                        <button className="btn btn-primary" onClick={() => history.push(`/rented-properties/details/${newPropertyID}`)}>
                            <span>Ver Informações</span>
                            <ArrowRightIcon className="w-6 h-6" />
                        </button>
                    </div>
                }
                {
                    !formSubmited &&
                    <div className="flex justify-between items-start">
                        <form className="w-1/2 mx-auto bg-white shadow hover:shadow-md rounded-md space-y-2">
                            <h1 className="px-8 py-4 border-b border-gray-100 flex justify-center items-center text-center text-2xl font-extrabold text-primary">
                                Registando Propriedade Sub-Arrendada
                            </h1>

                            {/* Get information of the property */}
                            <div className="px-8 py-2 grid grid-cols-2 gap-8">
                                <FormInput label="Designação" value={designation} onInput={e => setDesignation(e.target.value)} />
                                <FormInput label="Nome do Edifício (Prédio)" value={building} onInput={e => setBuilding(e.target.value)} />
                                <FormInput label="Andar" value={floor} onInput={e => setFloor(String(e.target.value).toUpperCase())} />
                                <FormInput label="Porta" value={door} onInput={e => setDoor(String(e.target.value).toUpperCase())} />
                                <FormInput label="Tipo de Propriedade" value={propertyType} onChange={() => {}} disabled={'disabled'} />
                                <div className="flex flex-col space-y-1">
                                    <label className="login-input-label">Estado da Propriedade</label>
                                    <select className="login-input" value={livingState} onChange={e => setLivingState(e.target.value)}>
                                        <option value="Habitada">Habitada</option>
                                        <option value="Desabitada">Desabitada</option>
                                        <option value="Abandonada">Abandonada</option>
                                        <option value="Em Construção">Em Construção</option>
                                    </select>
                                </div>
                                <FormInput label="Finalidade" value={propertyFunction} onInput={e => setPropertyFunction(e.target.value)} />
                                <FormInput label="Observação" value={observation} onInput={e => setObservation(e.target.value)} />
                            </div>
                            {/* Members of the property */}
                            {
                                propertyMembers.length > 0 &&
                                <div className="space-y-3">
                                    <h3 className="px-8 py-4 border-b border-t border-gray-100 text-xl font-bold text-primary">
                                        Membros da Propriedade
                                    </h3>

                                    <div className="px-8 space-y-3">
                                        {
                                            propertyMembers.map(citizen => (
                                                <div className="flex justify-between items-center" key={citizen.id}>
                                                    <Link target="_blank" to={`/citizens/details/${citizen.id}`} className="font-medium text-indigo-700 hover:underline">
                                                        {citizen.name}
                                                    </Link>

                                                    <div className="flex items-center space-x-4">
                                                        <select className="border border-gray-400 p-1 text-sm" onChange={handleOwnership} defaultValue={citizen.type} data-type={citizen.type} data-id={citizen.id}>
                                                            <option value="Proprietário">Proprietário</option>
                                                            <option value="Responsável">Responsável</option>
                                                            <option value="Membro">Membro</option>
                                                        </select>

                                                        <button type="button" onClick={() => handleRemoveCitizen(citizen.id)} className="p-1 rounded bg-red-500 hover:bg-red-700 font-medium text-white" title="Apagar membro?">
                                                            <XIcon className="w-6 h-6"/>
                                                        </button>
                                                    </div>
                                                </div>
                                            ))
                                        }
                                    </div>
                                </div>
                            }

                            {/* SUBMIT BUTTONS */}
                            <div className="border-t py-4 border-gray-200 flex justify-center items-center space-x-6">
                                <button onClick={handleSubmit} type="button" className="btn btn-primary">
                                    <HomeIcon className="w-6 h-6" />
                                    <span>Registar</span>
                                </button>

                                <button onClick={handleClear} type="reset" className="btn btn-danger">
                                    <BanIcon className="w-6 h-6" />
                                    <span>Limpar</span>
                                </button>
                            </div>
                        </form>

                        {/* Search menu */}
                        <div className="w-64 space-y-2">
                            <div className="bg-white shadow p-2 space-y-2">
                                <div>
                                    <h3 className="text-center text-sm bg-primary p-2 rounded-tl-md rounded-tr-md text-white font-bold">
                                        Membros da propriedade
                                    </h3>
                                    <form onSubmit={handleSearch}>
                                        <input value={searchItem} onInput={e => setSearchItem(e.target.value)} type="search" placeholder="Pesquisar nome" className="w-full bg-white p-1.5 rounded-bl-md rounded-br-md border-l-2 border-r-2 border-b-2 border-gray-300 hover:border-primary focus:border-primary outline-none transition duration-300 ease-out" />
                                    </form>
                                </div>
                                <div className="h-52 overflow-y-auto">
                                    {
                                        searchItems.length > 0 &&
                                        <div className="text-sm right-0 w-full bg-white">
                                            <div className="p-2 text-gray-600">
                                                {searchItems.map(searchItem =>
                                                    <button onClick={handleAddCitizen} data-type="Membro" data-id={searchItem._id} data-name={`${searchItem.first_name} ${searchItem.last_name}`} key={searchItem._id} type="button" className="w-full p-1 text-left hover:bg-indigo-200 font-medium">
                                                        {`${searchItem.first_name} ${searchItem.last_name}`}
                                                    </button>
                                                )}
                                            </div>
                                        </div>
                                    }
                                    {
                                        searchError &&
                                        <div className="w-64 text-red-500 font-bold">
                                            Nehum resultado encontrado
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                }
            </div>
        </div>
    );
}

export default CreateRentedProperty;
