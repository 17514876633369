import useFetch from '../../useFetch';
import Header from "../../Header";
import { PlusIcon, OfficeBuildingIcon } from '@heroicons/react/solid';
import { Link } from "react-router-dom";
import RentedPropertyPreview from './RentedPropertyPreview';
import { BASE_URL } from '../../../utils'

const RentedProperties = () => {
    const { isLoading, error, data: rentedProperties } = useFetch(BASE_URL + '/api/properties/rented');
    return (
        <div className="text-gray-600">
            <Header/>
            <div className="mx-6 lg:mx-20 mt-16 lg:mt-24 space-y-4 lg:space-y-5">
                <div className="flex justify-between items-center border-b-2 border-gray-200 pb-4">
                    <div className="flex items-center space-x-2">
                        <OfficeBuildingIcon className="w-7 h-7"/>
                        <h1 className="text-2xl font-bold text-gray-700">
                            Propriedades Registradas
                        </h1>
                    </div>
                    <Link to="/rented-properties/create" className="inline-flex justify-center items-center space-x-2 px-4 py-2.5 bg-primary hover:bg-blue-600 text-white font-medium rounded transition duration-300 ease-out">
                        <PlusIcon className="w-5 h-5"/>
                        <span>Adicionar</span>
                    </Link>
                </div>
                { error && <div className="text-red-500">Houve um erro no sistema</div> }
                { isLoading & <div className="font-medium">Carregando os dados...</div> }
                {
                    rentedProperties &&
                    <div className="grid grid-cols-3 gap-6">
                        { 
                            rentedProperties.map(property =>
                                (<RentedPropertyPreview property={property} key={property._id} /> ))
                        }
                    </div>
                }
            </div>
        </div>
    );
}
 
export default RentedProperties;
