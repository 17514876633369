import Header from "../Header";
import {Link, useParams, useHistory} from 'react-router-dom';
import useFetch from '../useFetch';
import {PrinterIcon, PencilIcon} from '@heroicons/react/solid';
import PropertyAttribute from './PropertyAttribute';
import MemberPreview from './MemberPreview';
import {BASE_URL} from "../../utils";

const PropertyDetails = () => {
    const {id} = useParams();
    const {isLoading, error, data: property} = useFetch(`${BASE_URL}/api/properties/${id}`);

    // Go to update property page to update current property information
    const history = useHistory();
    const handleUpdate = (propertyId) => {
        history.push(`/properties/update/${propertyId}`);
    };

    // Generate a PDF file containing the current property details and download
    const handlePrint = (property) => {
        // TODO: implement a PDF file generator
        console.log(property);
    };

    return (
        <div>
            <Header />
            <div className="mx-20 mt-24">
                {isLoading && <div className="font-medium">Carregando os dados...</div>}
                {error && <div className="text-red-500">Houve um erro no sistema</div>}
                {
                    property &&
                    <div className="w-3/5 mx-auto bg-white shadow-md rounded-md space-y-2">

                        {/* SHOW INFORMATION OF THE PROPERTY */}
                        <div className="space-y-4">
                            <div className="px-8 py-4 border-b border-gray-100 text-center space-y-2">
                                <h1 className="text-xl font-extrabold text-primary">
                                    Dados da Propriedade:
                                </h1>
                                {property.designation && <h2 className="text-lg font-medium text-primary italic">({property.designation}) </h2>}
                            </div>
                            <div className="grid grid-cols-2 gap-3 px-8 text-gray-800">
                                <div className="space-y-2">
                                    {property.designation && <PropertyAttribute title="Designação" attribute={property.designation} />}
                                    {property.number && <PropertyAttribute title="Número" attribute={property.number} />}
                                    {property.cellule && <PropertyAttribute title="Célula" attribute={property.cellule} />}
                                    {property.block && <PropertyAttribute title="Quarteirão" attribute={property.block} />}
                                    {property.neighborhood && <PropertyAttribute title="Bairro" attribute={property.neighborhood} />}
                                    {property.locality && <PropertyAttribute title="Localidade" attribute={property.locality} />}
                                    {property.administrative_post && <PropertyAttribute title="Posto Administrativo" attribute={property.administrative_post} />}
                                    {property.district && <PropertyAttribute title="Distrito" attribute={property.district} />}
                                    {property.province && <PropertyAttribute title="Província" attribute={property.province} />}
                                </div>

                                <div className="space-y-2">
                                    {property.street && <PropertyAttribute title="Rua" attribute={property.street} />}
                                    {property.avenue && <PropertyAttribute title="Avenida" attribute={property.avenue} />}
                                    {property.building && <PropertyAttribute title="Edifício" attribute={property.building} />}
                                    {property.floor && <PropertyAttribute title="Andar" attribute={property.floor} />}
                                    {property.door && <PropertyAttribute title="Porta" attribute={property.door} />}
                                    {property.property_type && <PropertyAttribute title="Tipo de Propriedade" attribute={property.property_type} />}
                                    {property.living_state && <PropertyAttribute title="Estado" attribute={property.living_state} />}
                                    {property.property_function && <PropertyAttribute title="Finalidade" attribute={property.property_function} />}
                                    {property.observation && <PropertyAttribute title="Observação" attribute={property.observation} />}
                                </div>
                            </div>
                        </div>

                        {/* SHOW CITIZENS THAT BELONG TO THIS PROPERTY */}
                        {
                            (property.owners.length !== 0 || property.keepers.length !== 0 || property.members.length !== 0) &&
                            <div className="space-y-4">
                                <h2 className="px-8 py-4 border-b border-t border-gray-100 text-lg font-extrabold text-primary">
                                    Agregado
                                </h2>
                                <div className="grid grid-cols-2 gap-6 px-8 text-gray-800">
                                    {property.owners.length !== 0 && <MemberPreview title='Proprietários' members={property.owners} />}
                                    {property.keepers.length !== 0 && <MemberPreview title='Responsáveis' members={property.keepers} />}
                                    {property.members.length !== 0 && <MemberPreview title='Membros' members={property.members} />}
                                </div>
                            </div>
                        }

                        {/* SHOW ALL RENTED PROPERTIES */}
                        {
                            property.rented_properties.length > 0 && property.rented_properties[0] &&
                            <div className="space-y-4">
                                <h2 className="px-8 py-4 border-b border-t border-gray-100 text-lg font-extrabold text-primary">
                                    Propriedades Arrendadas
                                </h2>
                                <div className="grid grid-cols-2 gap-8 px-8 text-gray-800">
                                    {property.rented_properties.map(rentedProperty =>
                                        <span key={rentedProperty._id}>
                                            <Link target="_blank" to={`/rented-properties/details/${rentedProperty._id}`} className="text-indigo-700 hover:underline">
                                                {
                                                    rentedProperty.designation ?
                                                        <span>{rentedProperty.designation}</span> :
                                                        <span>Ver detalhes</span>
                                                }
                                            </Link>
                                        </span>
                                    )}
                                </div>
                            </div>
                        }

                        {/* SUBMIT BUTTONS */}
                        <div className="border-t py-4 border-gray-100 flex justify-center items-center space-x-4">
                            <button className="btn btn-primary" onClick={() => handleUpdate(property._id)}>
                                <PencilIcon className="w-6 h-6" />
                                <span>Actualizar</span>
                            </button>
                            <button className="btn btn-success" onClick={() => handlePrint(property)}>
                                <PrinterIcon className="w-6 h-6" />
                                <span>Imprimir</span>
                            </button>
                        </div>

                    </div>
                }
            </div>
        </div>
    );
}

export default PropertyDetails;
