import {useState} from "react";
import Header from "../Header";
import CitizenPreview from "./CitizenPreview";
import useFetch from '../useFetch';
import {BASE_URL} from "../../utils";

const Citizens = () => {
    const {isLoading, error, data: citizens} = useFetch(`${BASE_URL}/api/citizens/all`);

    // Citizens search states
    const [citizenToSearch, setCitizenToSearch] = useState('');
    const [citizenSearchError, setCitizenSearchError] = useState(false);
    const [searchedCitizens, setSearchedCitizens] = useState([]);

    function handleSearchCitizen(event) {
        event.preventDefault();

        fetch(`${BASE_URL}/api/citizens/search/${citizenToSearch}`)
            .then(response => {
                if (!response.ok)
                    throw new Error();
                return response.json();
            })
            .then(result => {
                if (result.length > 0) {
                    setCitizenSearchError(false);
                    setSearchedCitizens(result);
                } else {
                    throw new Error();
                }
            })
            .catch(_error => {
                setSearchedCitizens([]);
                setCitizenSearchError(true);
            });
    }
    return (
        <>
            <Header />
            <div className="mx-20 mt-24 space-y-6">

                {/* NAME OF THE PAGE */}
                <div className="flex justify-between items-center border-b border-gray-200 pb-4 ">
                    <h1 className="font-bold text-3xl text-gray-900">Cidadãos Registados</h1>

                    <form onSubmit={handleSearchCitizen} className="w-1/2">
                        <input value={citizenToSearch} onInput={e => setCitizenToSearch(e.target.value)} type="search" placeholder="Pesquise aqui: pelo nome, apelido, proveniência, BI ou ocupação." className="w-full p-2 rounded border-2 border-gray-300 hover:border-blue-700 focus:border-blue-700 placeholder-gray-700 text-black transition duration-300 ease-out focus:outline-none" />
                    </form>
                </div>

                {error && <div className="text-2xl font-bold text-red-500">Houve um erro no sistema...</div>}

                {isLoading && <div className="text-2xl font-bold font-medium">Carregando os dados...</div>}

                {
                    citizens && searchedCitizens.length === 0 &&
                    <div className="grid grid-cols-3 gap-6">
                        {citizens.map(citizen => <CitizenPreview citizen={citizen} key={citizen._id} />)}
                    </div>
                }

                {
                    searchedCitizens.length >= 0 &&
                    <div className="grid grid-cols-3 gap-6">
                        {searchedCitizens.map(citizen => <CitizenPreview citizen={citizen} key={citizen._id} />)}
                    </div>
                }

                {citizenSearchError && <div className="text-2xl font-bold text-red-500">Nehum resultado encontrado...</div>}
            </div>
        </>
    );
}

export default Citizens;
