import Header from '../Header';
import FormInput from '../login/FormInput';
import { useHistory } from 'react-router-dom';
import { useState } from 'react';
import {BASE_URL} from "../../utils";
import { HomeIcon, BanIcon } from "@heroicons/react/solid";

const CreateCitizen = () => {
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [birthdate, setBirthDate] = useState('1990-01-01');
    const [provenance, setProvenance] = useState('');
    const [nationalID, setNationalID] = useState('');
    const [occupation, setOccupation] = useState('');
    const [occupationLocation, setOccupationLocation] = useState('');
    const [occupationContact, setOccupationContact] = useState('');
    const [firstContact, setFirstContact] = useState(null);
    const [secondContact, setSecondContact] = useState(null);
    const [thirdContact, setThirdContact] = useState(null);
    const [observation, setObservation] = useState('');

    const [formSubmited, setFormSubmited] = useState(true);
    const [submissionError, setSubmissionError] = useState(false);
    const [citizenId, setCitizenId] = useState(null);

    function handleSubmit(event) {
        event.preventDefault();

        const citizenInfo = {
            first_name: firstName,
            last_name: lastName,
            birth_date: birthdate,
            provenance: provenance,
            national_identification: nationalID,
            occupation: occupation,
            occupation_location: occupationLocation,
            occupation_contact: occupationContact,
            contacts: [],
            observation: observation
        };
        
        // Prevent pushing contacts that are null.
        if (firstContact) citizenInfo.contacts.push(firstContact);
        if (secondContact) citizenInfo.contacts.push(secondContact);
        if (thirdContact) citizenInfo.contacts.push(thirdContact);

        fetch(`${BASE_URL}/api/citizens`, {
                headers: { 'Content-Type': 'application/json' },
                method: 'POST',
                body: JSON.stringify(citizenInfo)
            })
            .then(res => {
                if (!res.ok) throw Error();
                return res.json()
            })
            .then(res => {
                // Show success message
                setFormSubmited(false);
                setCitizenId(res._id);
            })
            .catch(err => {
                // Show error message
                setSubmissionError(true);
                setFormSubmited(false);
            });
    };

    function handleClear() {
        setFirstName('');
        setLastName('');
        setBirthDate(Date.now());
        setProvenance('');
        setNationalID('');
        setOccupation('');
        setOccupationLocation('');
        setOccupationContact('');
        setFirstContact('');
        setSecondContact('');
        setThirdContact('');
        setObservation('');
    }

    const history = useHistory();

    function handleFormSuccess(){
        history.push(`/citizens/details/${citizenId}`);
    }

    function handleFormError(){
        setSubmissionError(false);
        setFormSubmited(true);
    }

    return (
        <>
            <Header />
            <div className="mx-20 mt-24">
                {
                    citizenId &&
                    <div className="w-1/2 mx-auto flex flex-col justify-center items-center space-y-8 p-8 bg-white shadow-md rounded-md">
                        <div className="text-center space-y-4">
                            <h2 className="text-2xl font-bold text-green-500">
                                Cidadão registado com sucesso
                            </h2>
                            <p className="text-lg">Click abaixo para confirmar os dados</p>
                        </div>

                        <button className="btn btn-success" onClick={handleFormSuccess}>
                            Confirmar Dados
                        </button>
                    </div>
                }

                {
                    submissionError &&
                    <div className="w-1/2 mx-auto flex flex-col justify-center items-center space-y-8 p-8 bg-white shadow-md rounded-md">
                        <div className="text-center space-y-4">
                            <h2 className="text-2xl font-bold text-red-500">
                                Houve um erro no registo
                            </h2>

                            <p className="text-lg">Ooops, parece que occoreu um erro</p>
                        </div>

                        <button className="btn btn-danger" onClick={handleFormError}>
                            Tentar Novamente
                        </button>
                    </div>
                }

                {
                    formSubmited &&
                    <form className="w-1/2 mx-auto bg-white shadow-md rounded-md space-y-2">
                        <h1 className="px-8 py-4 border-b border-gray-100 flex justify-center items-center text-center text-2xl font-extrabold text-primary">
                            Registando Cidadão
                        </h1>
                        <div className="px-8 py-2 grid grid-cols-2 gap-8">
                            <FormInput label="Nome" value={firstName} onInput={(e) => setFirstName(e.target.value)}/>
                            <FormInput label="Apelido" value={lastName} onInput={(e) => setLastName(e.target.value)}/>
                            <FormInput label="Data de Nascimento" type="date" value={birthdate} onInput={(e) => setBirthDate(e.target.value)}/>
                            <FormInput label="Proveniência" value={provenance} onInput={(e) => setProvenance(e.target.value)}/>
                            <FormInput label="Bilhete de Identidate" value={nationalID} onInput={(e) => setNationalID(e.target.value)}/>
                            <FormInput label="Ocupação" value={occupation} onInput={(e) => setOccupation(e.target.value)}/>
                            <FormInput label="Local da Ocupação" value={occupationLocation} onInput={(e) => setOccupationLocation(e.target.value)}/>
                            <FormInput label="Contacto da Ocupação" value={occupationContact} onInput={(e) => setOccupationContact(e.target.value)}/>
                            <FormInput label="Contacto Pessoal" value={firstContact} onInput={(e) => setFirstContact(e.target.value)}/>
                            <FormInput label="Contacto Alternativo 1" value={secondContact} onInput={(e) => setSecondContact(e.target.value)}/>
                            <FormInput label="Contacto Alternativo 2" value={thirdContact} onInput={(e) => setThirdContact(e.target.value)}/>
                            <FormInput label="Obeservação" value={observation} onInput={(e) => setObservation(e.target.value)}/>
                        </div>
                        
                        <div className="border-t py-4 border-gray-100 flex justify-center items-center space-x-6">
                            <button onClick={handleSubmit} type="button" className="btn btn-primary">
                                <HomeIcon className="w-6 h-6" />
                                <span>Registar</span>
                            </button>

                            <button onClick={handleClear} type="reset" className="btn btn-danger">
                                <BanIcon className="w-6 h-6" />
                                <span>Limpar</span>
                            </button>
                        </div>
                    </form>
                }
            </div>
        </>
    );
}
 
export default CreateCitizen;