import { Link, useParams } from 'react-router-dom';
import useFetch from '../useFetch';
import Header from "../Header";
import {BASE_URL, getDate} from "../../utils"

const CitizenDetails = () => {
    const { id } = useParams();
    const { isLoading, error, data: citizen } = useFetch(`${BASE_URL}/api/citizens/${id}`);

    return (
        <div>
            <Header />
            <div className="mx-20 mt-24">
                { isLoading && <div className="font-medium">Carregando os dados...</div> }
                { error && <div className="text-red-500">Houve um erro no sistema</div> }
                {
                    citizen &&
                    <div className="w-1/2 mx-auto bg-white shadow hover:shadow-md rounded-md space-y-2">
                        <div className="px-8 py-4 border-b border-gray-200 space-y-1 text-center ">
                            <h1 className="text-xl font-extrabold text-primary">
                                Visualizando Dados de:
                            </h1>
                            <h2 className="text-lg italic text-purple-500">
                                { `(${citizen.first_name} ${citizen.last_name})` }
                            </h2>
                        </div>
                        <div className="space-y-3.5 px-8">
                            <div className="flex space-x-3">
                                <h3 className="font-medium">Nome:</h3>
                                <h4>{ citizen.first_name }</h4>
                            </div>
                            <div className="flex space-x-3">
                                <h3 className="font-medium">Apelido:</h3>
                                <h4>{ citizen.last_name }</h4>
                            </div>
                            {
                                citizen.birth_date &&
                                <div className="flex space-x-3">
                                    <h3 className="font-medium">Data de Nascimento:</h3>
                                    <h4>{ getDate(citizen.birth_date) }</h4>
                                </div>
                            }
                            {
                                citizen.provenance &&
                                <div className="flex space-x-3">
                                    <h3 className="font-medium">Proveniência:</h3>
                                    <h4>{ citizen.provenance }</h4>
                                </div>
                            }
                            {
                                citizen.national_identification &&
                                <div className="flex space-x-3">
                                    <h3 className="font-medium">Bilhete de Identidade:</h3>
                                    <h4 className="italic">{ citizen.national_identification }</h4>
                                </div>
                            }
                            {
                                citizen.occupation &&
                                <div className="flex space-x-3">
                                    <h3 className="font-medium">Ocupação:</h3>
                                    <h4>{ citizen.occupation }</h4>
                                </div>
                            }
                            {
                                citizen.occupation_location &&
                                <div className="flex space-x-3">
                                    <h3 className="font-medium">Local da Ocupação:</h3>
                                    <h4>{ citizen.occupation_location }</h4>
                                </div>
                            }
                            {
                                citizen.occupation_contact &&
                                <div className="flex space-x-3">
                                    <h3 className="font-medium">Contacto da Ocupação:</h3>
                                    <h4>{ citizen.occupation_contact }</h4>
                                </div>
                            }
                            {
                                citizen.contacts.length != 0 &&
                                <div className="space-y-1">
                                    <h3 className="font-medium">Contactos Pessoais:</h3>
                                    <div className="grid grid-cols-3">
                                        { citizen.contacts.map(contact => <h4 className="italic" key={`${contact}${Math.random() * 10}`}>{ contact }</h4>) }
                                    </div>
                                </div>
                            }
                            {
                                citizen.observation != '' &&
                                <div className="flex space-x-3">
                                    <h3 className="font-medium">Obeservação:</h3>
                                    <h4>{ citizen.observation }</h4>
                                </div>
                            }
                        </div>
                        <div className="border-t py-4 border-gray-200 flex justify-center items-center space-x-4">
                            <Link to={`/citizens/update/${citizen._id}`} className="btn btn-primary">
                                Actualizar
                            </Link>
                        </div>
                    </div>
                }
            </div>
        </div>
    );
}
 
export default CitizenDetails;
