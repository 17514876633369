import {useHistory} from 'react-router-dom';
import FormInput from '../login/FormInput';
import Header from "../Header";
import {ArrowRightIcon, BanIcon, HomeIcon, XIcon} from '@heroicons/react/solid';
import {useState} from 'react';
import {BASE_URL} from '../../utils';
import {Link} from "react-router-dom";

const CreateProperty = () => {
    // History object to move backward or forward
    const history = useHistory();


    // Once the form is submited successfully this state will contain
    // the ID of the new property saved on DB, in order to redirect
    // to the property details page.
    const [newPropertyID, setNewPropertyID] = useState(null);

    // Property details
    const [designation, setDesignation] = useState('');
    const [number, setNumber] = useState('');
    const [cellule, setCellule] = useState('');
    const [block, setBlock] = useState('');
    const [neighborhood, setNeighborhood] = useState('');
    const [locality, setLocality] = useState('');
    const [administrativePost, setAdministrativePost] = useState('');
    const [district, setDistrict] = useState('');
    const [province, setProvince] = useState('Maputo');
    const [street, setStreet] = useState('');
    const [avenue, setAvenue] = useState('');
    const [building, setBuilding] = useState('');
    const [floor, setFloor] = useState('');
    const [door, setDoor] = useState('');
    const [propertyType, setPropertyType] = useState('Residência');
    const [livingState, setLivingState] = useState('Habitada');
    const [propertyFunction, setPropertyFunction] = useState('');
    const [observation, setObservation] = useState('');

    // State for citizens
    const [citizensUI, setCitizensUI] = useState([]);
    const [citizensToAdd, setCitizensToAdd] = useState([]);

    function handleAddCitizen(event) {
        const newCitizen = {
            name: event.target.dataset.name,
            type: 'Membro',
            id: event.target.dataset.id
        };

        // Avoid duplicates
        for (const citizen of citizensUI)
            if (citizen.id === newCitizen.id)
                return;

        // Show citizens on UI.
        let newCitizens = citizensUI.filter(citizen =>
            citizen.id !== newCitizen.id);
        setCitizensUI([...newCitizens, newCitizen]);

        // Add citizens to array that will be sent to database.
        newCitizens = citizensToAdd.filter(citizen =>
            citizen.id !== newCitizen.id);
        setCitizensToAdd([...newCitizens, newCitizen]);
    }

    function handleRemoveCitizen(citizenId) {
        // Remove from UI
        let newCitizens = citizensUI.filter(citizen =>
            citizen.id !== citizenId);
        setCitizensUI(newCitizens);

        // Remove from list of members to be saved on database
        newCitizens = citizensToAdd.filter(citizen =>
            citizen.id !== citizenId);
        setCitizensToAdd([...newCitizens]);
    }

    function handleOwnership(event) {
        const citizens = citizensToAdd;
        citizens.forEach(citizen => {
            if (citizen.id === event.target.dataset.id)
                citizen.type = event.target.value;
        });
        setCitizensToAdd([...citizens]);
    }


    // Rented properties
    const [propertiesUI, setPropertiesUI] = useState([]);
    const [propertiesToAdd, setPropertiesToAdd] = useState([]);

    function handleAddProperty(description, id) {
        // Create new rented property
        description = description ? description : 'Ver Detalhes'
        const newProperty = {description, id};

        // Show properties on UI (avoid duplicates)
        let properties = propertiesUI.filter(property =>
            property.id !== id);
        setPropertiesUI([...properties, newProperty]);

        // Mark properties to be added on DB (avoid duplicates)
        properties = propertiesToAdd.filter(rentedProperty =>
            rentedProperty.id !== id);
        setPropertiesToAdd([...properties, newProperty]);
    }

    function handleRemoveProperty(propertyId) {
        // Remove property from UI
        let newProperties = propertiesUI.filter(property =>
            property.id !== propertyId);
        setPropertiesUI([...newProperties]);

        // Unmark property (mark to not be added to DB)
        newProperties = propertiesToAdd.filter(rentedProperty =>
            rentedProperty.id !== propertyId);
        setPropertiesToAdd([...newProperties]);
    }

    // States form the submission of the form
    const [formSubmited, setFormSubmited] = useState(false);
    const [submissionError, setFormSubmissionError] = useState(false);
    const [processingSubmission, setProcessingSubmission] = useState(false);

    function handleSubmit(e) {
        // Form was submitted
        setFormSubmited(true);
        e.preventDefault();

        // Get all members
        const owners = getCitizens('Proprietário');
        const keepers = getCitizens('Responsável');
        const members = getCitizens('Membro');

        function getCitizens(type) {
            const add = citizensToAdd
                .filter(citizen => citizen.type === type)
                .map(owner => owner.id);
            return add;
        }

        // Get all rented properties
        const addProperties = propertiesToAdd.map(property => property.id);
        console.log(addProperties);

        // Property to save on database
        const property = {
            property_details: {
                designation: designation,
                number: number,
                cellule: cellule,
                block: block,
                neighborhood: neighborhood,
                locality: locality,
                administrative_post: administrativePost,
                district: district,
                province: province,
                street: street,
                avenue: avenue,
                building: building,
                floor: floor,
                door: door,
                property_type: propertyType,
                living_state: livingState,
                property_function: propertyFunction,
                observation: observation
            },
            property_owners: owners,
            property_keepers: keepers,
            property_members: members,
            rented_properties: addProperties
        };

        fetch(BASE_URL + "/api/properties", {
            headers: {'Content-Type': 'application/json'},
            method: 'POST',
            body: JSON.stringify(property)
        })
            .then(res => {
                if (!res.ok)
                    throw Error();
                return res.json();
            })
            .then(newProperty => {
                setFormSubmissionError(false);
                setProcessingSubmission(false);

                // Get new registered property ID from database
                // to redirect to property details page.
                // This first show a success message
                setNewPropertyID(newProperty._id);
            })
            .catch(_err => setFormSubmissionError(true));
    }

    function handleClear() {
        setDesignation('');
        setNumber('');
        setCellule('');
        setBlock('');
        setNeighborhood('');
        setLocality('');
        setAdministrativePost('');
        setDistrict('');
        setProvince('Maputo');
        setStreet('');
        setAvenue('');
        setBuilding('');
        setFloor('');
        setDoor('');
        setPropertyType('Residência');
        setLivingState('Habitada');
        setPropertyFunction('');
        setObservation('');

        setCitizensToAdd([]);
        setCitizensUI([]);
        setPropertiesUI([]);
        setPropertiesToAdd([]);
    }

    // Citizens search states
    const [citizenToSearch, setCitizenToSearch] = useState('');
    const [citizenSearchError, setCitizenSearchError] = useState(false);
    const [searchedCitizens, setSearchedCitizens] = useState([]);

    function handleSearchCitizen(event) {
        event.preventDefault();

        fetch(BASE_URL + `/api/citizens/search/${citizenToSearch}`)
            .then(response => {
                if (!response.ok)
                    throw new Error();
                return response.json();
            })
            .then(result => {
                if (result.length > 0) {
                    setCitizenSearchError(false);
                    setSearchedCitizens([...result]);
                } else {
                    throw new Error();
                }
            })
            .catch(_error => {
                setSearchedCitizens([]);
                setCitizenSearchError(true);
            });
    }

    // Properties search states
    const [propertyToSearch, setPropertyToSearch] = useState('');
    const [propertySearchError, setPropertySearchError] = useState(false);
    const [searchedProperties, setSearchedProperties] = useState([]);

    function handleSearchProperty(event) {
        event.preventDefault();

        fetch(BASE_URL + `/api/properties/search/rented/${propertyToSearch}`)
            .then(response => {
                if (!response.ok)
                    throw new Error();
                return response.json();
            })
            .then(result => {
                if (result.length > 0) {
                    setPropertySearchError(false);
                    setSearchedProperties([...result]);
                } else {
                    throw new Error();
                }
            })
            .catch(_error => {
                setSearchedProperties([]);
                setPropertySearchError(true);
            });
    }

    return (
        <div>
            <Header />
            <div className="mx-20 mt-24">
                {
                    // Show a processing message
                    processingSubmission &&
                    <div className="p-8 space-y-4 w-1/2 mx-auto flex flex-col justify-center items-center bg-white shadow-md hover:shadow-lg rounded-md">
                        <h2 className="text-3xl font-extrabold text-green-500">
                            Processando o registro...
                        </h2>
                    </div>
                }
                {
                    // Show an error if the form submission had an error
                    submissionError &&
                    <div className="p-8 space-y-4 w-1/2 mx-auto flex flex-col justify-center items-center bg-white shadow-md hover:shadow-lg rounded-md">
                        <div className="text-center space-y-5">
                            <h2 className="text-3xl font-bold text-red-500">
                                Occorreu um erro no registro
                            </h2>
                            <p className="text-lg font-medium">
                                Por favor, tente novamente clicando no botao abaixo
                            </p>
                        </div>
                        <button className="btn btn-primary" onClick={() => {setProcessingSubmission(false); setFormSubmissionError(false); setFormSubmited(false)}}>
                            Tentar Novamente
                        </button>
                    </div>
                }
                {
                    // Show a success message
                    newPropertyID &&
                    <div className="p-8 space-y-4 w-1/2 mx-auto flex flex-col justify-center items-center bg-white shadow-md hover:shadow-lg rounded-md">
                        <div className="text-center space-y-5">
                            <h2 className="text-3xl font-bold text-green-500">
                                Propriedade registrada com sucesso
                            </h2>
                            <p className="text-lg font-medium">
                                Veja os dados e informações da propriedade
                            </p>
                        </div>
                        <button className="btn btn-primary" onClick={() => history.push(`/properties/details/${newPropertyID}`)}>
                            <span>Ver Informações</span>
                            <ArrowRightIcon className="w-6 h-6" />
                        </button>
                    </div>
                }
                {
                    !formSubmited &&
                    <div className="w-full grid grid-cols-12 gap-10">
                        {/* FORM DATA */}
                        <form className="col-span-9 bg-white shadow-md rounded-md space-y-2">
                            <h1 className="px-8 py-4 border-b border-gray-100 flex justify-center items-center text-center text-2xl font-extrabold text-primary">
                                Registando Nova Propriedade
                            </h1>

                            <div className="px-8 py-2 grid grid-cols-3 gap-8">
                                <FormInput label="Designação" value={designation} onInput={e => setDesignation(e.target.value)} />
                                <FormInput label="Número" value={number} onInput={e => setNumber(String(e.target.value).toUpperCase())} />
                                <FormInput label="Célula" value={cellule} onInput={e => setCellule(String(e.target.value).toUpperCase())} />
                                <FormInput label="Quarteirão" value={block} onInput={e => setBlock(String(e.target.value).toUpperCase())} />
                                <FormInput label="Bairro" value={neighborhood} onInput={e => setNeighborhood(e.target.value)} />
                                <FormInput label="Localidade" value={locality} onInput={e => setLocality(e.target.value)} />
                                <FormInput label="Posto Administrativo" value={administrativePost} onInput={e => setAdministrativePost(e.target.value)} />
                                <FormInput label="Distrito" value={district} onInput={e => setDistrict(e.target.value)} />
                                <div className="flex flex-col space-y-1">
                                    <label className="login-input-label">Província</label>
                                    <select className="login-input" defaultValue={province} onChange={e => setProvince(e.target.value)}>
                                        <option value="Maputo">Maputo</option>
                                        <option value="Maputo Cidade">Maputo Cidade</option>
                                        <option value="Gaza">Gaza</option>
                                        <option value="Inhambane">Inhambane</option>
                                        <option value="Sofala">Sofala</option>
                                        <option value="Manica">Manica</option>
                                        <option value="Tete">Tete</option>
                                        <option value="Zambézia">Zambézia</option>
                                        <option value="Nampula">Nampula</option>
                                        <option value="Niassa">Niassa</option>
                                        <option value="Cabo Delgado">Cabo Delgado</option>
                                    </select>
                                </div>
                                <FormInput label="Rua" value={street} onInput={e => setStreet(e.target.value)} />
                                <FormInput label="Avenida" value={avenue} onInput={e => setAvenue(e.target.value)} />
                                <FormInput label="Nome do Edifício (Prédio)" value={building} onInput={e => setBuilding(e.target.value)} />
                                <FormInput label="Andar" value={floor} onInput={e => setFloor(String(e.target.value).toUpperCase())} />
                                <FormInput label="Porta" value={door} onInput={e => setDoor(String(e.target.value).toUpperCase())} />
                                <div className="flex flex-col space-y-1">
                                    <label className="login-input-label">Tipo de Propriedade</label>
                                    <select className="login-input" value={propertyType} onChange={e => setPropertyType(e.target.value)}>
                                        <option value="Residência">Residência</option>
                                        <option value="Sub Arrendada">Sub Arrendada</option>
                                        <option value="Arrendada">Arrendada</option>
                                        <option value="Condomínio">Condomínio</option>
                                    </select>
                                </div>
                                <div className="flex flex-col space-y-1">
                                    <label className="login-input-label">Estado da Propriedade</label>
                                    <select className="login-input" value={livingState} onChange={e => setLivingState(e.target.value)}>
                                        <option value="Habitada">Habitada</option>
                                        <option value="Desabitada">Desabitada</option>
                                        <option value="Abandonada">Abandonada</option>
                                        <option value="Em Construção">Em Construção</option>
                                    </select>
                                </div>
                                <FormInput label="Finalidade" value={propertyFunction} onInput={e => setPropertyFunction(e.target.value)} />
                                <FormInput label="Observação" value={observation} onInput={e => setObservation(e.target.value)} />
                            </div>

                            {
                                /* MEMBERS OF THE PROPERTY */
                                citizensUI.length > 0 &&
                                <div className="space-y-3">
                                    <h3 className="px-8 py-4 border-b border-t border-gray-100 text-xl font-bold text-primary">
                                        Membros da Propriedade
                                    </h3>

                                    <div className="px-8 space-y-3">
                                        {
                                            citizensUI.map(citizen => (
                                                <div className="flex justify-between items-center" key={citizen.id}>
                                                    <Link target="_blank" to={`/citizens/details/${citizen.id}`} className="font-medium text-indigo-700 hover:underline">
                                                        {citizen.name}
                                                    </Link>
                                                    <div className="flex items-center space-x-4">
                                                        <select className="border border-gray-400 p-1 text-sm" defaultValue='Membro' onChange={handleOwnership} data-id={citizen.id}>
                                                            <option value="Proprietário">Proprietário</option>
                                                            <option value="Responsável">Responsável</option>
                                                            <option value="Membro">Membro</option>
                                                        </select>

                                                        <button type="button" onClick={() => handleRemoveCitizen(citizen.id)} className="p-1 rounded bg-red-500 hover:bg-red-700 font-medium text-white" title="Apagar membro?">
                                                            <XIcon className="w-6 h-6"/>
                                                        </button>
                                                    </div>
                                                </div>
                                            ))
                                        }
                                    </div>
                                </div>
                            }

                            {
                                /* RENTED PROPERTIES */
                                propertiesUI.length > 0 &&
                                <div className="space-y-3">
                                    <h3 className="px-8 py-4 border-b border-t border-gray-100 text-xl font-bold text-primary">
                                        Propriedades Arrendadas
                                    </h3>

                                    <div className="px-8 space-y-3">
                                        {
                                            propertiesUI.map(property => (
                                                <div className="flex justify-between items-center" key={property.id}>
                                                    <Link to={`/rented-properties/details/${property.id}`} target="_blank" className="font-medium text-indigo-700 hover:underline">
                                                        {property.description ? property.description : 'Ver detalhes'}
                                                    </Link>

                                                    <button type="button" onClick={() => handleRemoveProperty(property.id)} className="p-1 rounded bg-red-500 hover:bg-red-700 font-medium text-sm text-white" title="Remover propriedade?">
                                                        <XIcon className="w-6 h-6"/>
                                                    </button>
                                                </div>
                                            ))
                                        }
                                    </div>
                                </div>
                            }

                            {/* SUBMIT BUTTONS */}
                            <div className="border-t py-4 border-gray-100 flex justify-center items-center space-x-6">
                                <button onClick={handleSubmit} type="button" className="btn btn-primary">
                                    <HomeIcon className="w-6 h-6" />
                                    <span>Registar</span>
                                </button>

                                <button onClick={handleClear} type="reset" className="btn btn-danger">
                                    <BanIcon className="w-6 h-6" />
                                    <span>Limpar</span>
                                </button>
                            </div>
                        </form>

                        {/* SEARCH CITIZENS AND PROPERTIES */}
                        <div className="col-span-3 space-y-2">
                            <div className="bg-white shadow p-2 space-y-2">
                                <div>
                                    <h3 className="text-center text-sm bg-primary p-2 rounded-tl-md rounded-tr-md text-white font-bold">
                                        Membros da propriedade
                                    </h3>

                                    <form onSubmit={handleSearchCitizen}>
                                        <input value={citizenToSearch} onInput={e => setCitizenToSearch(e.target.value)} type="search" placeholder="Pesquisar nome" className="w-full bg-white p-1.5 rounded-bl-md rounded-br-md border-l-2 border-r-2 border-b-2 border-gray-300 hover:border-primary focus:border-primary outline-none transition duration-300 ease-out" />
                                    </form>
                                </div>

                                <div className="h-52 overflow-y-auto">
                                    {
                                        searchedCitizens.length > 0 &&
                                        <div className="text-sm right-0 w-full bg-white">
                                            <div className="p-2 text-gray-600">
                                                {searchedCitizens.map(citizen =>
                                                    <button onClick={handleAddCitizen} data-id={citizen._id} data-name={`${citizen.first_name} ${citizen.last_name}`} key={citizen._id} type="button" className="w-full p-1 text-left hover:bg-indigo-200 font-medium">
                                                        {`${citizen.first_name} ${citizen.last_name}`}
                                                    </button>
                                                )}
                                            </div>
                                        </div>
                                    }

                                    {citizenSearchError && <div className="w-64 text-red-500 font-bold">Nehum resultado encontrado</div>}
                                </div>
                            </div>

                            {/* SEARCH RENTED PROPERTIES MENU */} <div className="bg-white shadow p-2 space-y-2">
                                <div>
                                    <h3 className="text-center text-sm bg-primary p-2 rounded-tl-md rounded-tr-md text-white font-bold">
                                        Propriedades Arrendadas
                                    </h3>

                                    <form onSubmit={handleSearchProperty}>
                                        <input value={propertyToSearch} onInput={e => setPropertyToSearch(e.target.value)} type="search" placeholder="Pesquisar renda" className="w-full bg-white p-1.5 rounded-bl-md rounded-br-md border-l-2 border-r-2 border-b-2 border-gray-300 hover:border-primary focus:border-primary outline-none transition duration-300 ease-out" />
                                    </form>
                                </div>

                                <div className="h-52 overflow-y-auto">
                                    {
                                        // Show searched properties
                                        searchedProperties.length > 0 &&
                                        <div className="text-sm right-0 w-full bg-white p-2 text-gray-600">
                                            {searchedProperties.map(property =>
                                                <button onClick={() => handleAddProperty(property.designation, property._id)} key={property._id} className="w-full p-1 text-left hover:bg-indigo-200 font-medium">
                                                    {property.designation ? property.designation : 'Sem densignacao'}
                                                </button>
                                            )}
                                        </div>
                                    }

                                    {propertySearchError && <div className="w-64 text-red-500 font-bold">Nehum resultado encontrado</div>}
                                </div>
                            </div>
                        </div>
                    </div>
                }
            </div>
        </div>
    );
}

export default CreateProperty;
