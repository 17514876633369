import { useState, useEffect } from "react";

const useFetch = (url, _body = '', _method = 'GET', _headers = { 'Content-Type': 'application/json' }) => {
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);
    const [data, setData] = useState(null);
    const abortController = new AbortController();

    useEffect(() => {
        const request = new Request(url, {
            signal: abortController.signal,
            method: _method,
            headers: _headers
        });

        if (_method !== 'GET' && _method !== 'DELETE') {
            request.body = JSON.stringify(_body);
        }

        setData(null);
        setIsLoading(true);
        setError(null);

        fetch(request)
            .then(res => {
                if (!res.ok) throw Error("Não foi possível obter os dados para essa página.");
                return res.json();
            })
            .then(result => {
                setData(result);
                setIsLoading(false);
                setError(null);
            })
            .catch(err => {
                if (!(err.name === "AbortError")) {
                    setIsLoading(false);
                    setError(err.message);
                }
            });

        return () => abortController.abort();
    }, [url]);

    return { data, isLoading, error };
}

export default useFetch;