import {useHistory} from 'react-router-dom';
import {useState} from 'react';

import FormCaption from './FormCaption';
import FormInput from './FormInput';
import FormButton from './FormButton';
import FormLink from './FormLink';
import FormCheckbox from './FormCheckbox';

const LoginForm = () => {
    const history = useHistory();
    const [loginForm, setLoginForm] = useState(true);
    const [generateForm, setGenerateForm] = useState(false);
    const [confirmForm, setConfirmForm] = useState(false);
    const [updateForm, setUpdateForm] = useState(false);

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState(false);

    function handleLogin(event) {
        event.preventDefault();

        let isUser = false;
        switch (email){
            case 'a.guambe62@gmail.com':
            case 'marioallfred@gmail.com':
                if (password === 'Mutemba32' || password === 'guambe123')
                    isUser = true;
                break;
        }

        if (isUser) history.push('/dashboard');
        else setError(true);
    }

    return (
        <div>
            {
                // Login Form
                loginForm &&
                <div className="h-screen flex flex-col justify-center items-center space-y-8">
                    <form className="w-96 space-y-6">
                        <div className="space-y-2">
                            <FormCaption content="Entrar no Sistema" />
                            {
                                error &&
                                <h3 className="text-red-500 text-center">Email ou password não validadas</h3>
                            }
                        </div>
                        <FormInput label="Email" onInput={e => setEmail(e.target.value)} value={email} />
                        <FormInput label="Password" type='password' onInput={e=> setPassword(e.target.value)} value={password} />
                        <FormButton content="Entrar" type="button" onClick={handleLogin}
                            btnStyle="bg-primary hover:bg-blue-700 text-white" />
                    </form>
                </div>
            }

            {
                // Generate Confirmation Code Form
                generateForm &&
                <div className="h-screen flex flex-col justify-center items-center space-y-8">
                    <FormCaption content="Gerar Código de Recuperação" />
                    <form autoComplete="off" className="w-96 space-y-6">
                        <p className="text-lg">
                            Por favor introduza o email que foi usado para
                            registrar a sua conta no sistema:
                        </p>
                        <FormInput label="Email" />
                        <FormButton type="submit" content="Enviar Código"
                            btnStyle="bg-primary hover:bg-blue-700 text-white" />
                        <FormLink type="button" content="Cancelar" />
                    </form>
                </div>
            }
            {
                // Confirm Generated Code Form
                confirmForm &&
                <div className="h-screen flex flex-col justify-center items-center space-y-8">
                    <FormCaption content="Confirmar Código de Recuperação" />
                    <form autoComplete="off" className="w-96 space-y-6">
                        <p className="text-lg">
                            Por favor introduza o código de recuperação enviado para seu email no campo
                            a seguir:
                        </p>
                        <FormInput label="Código" />
                        <FormButton type="submit" content="Confirmar Código"
                            btnStyle="bg-primary hover:bg-blue-700 text-white" />
                        <FormLink title="Cancelar recuperação" content="Cancelar" />
                    </form>
                </div>
            }
            {
                // Update Password Form
                updateForm &&
                <div className="h-screen flex flex-col justify-center items-center">
                    <form className="w-96 space-y-6">
                        <FormCaption content="Actualize a Nova Password" />
                        <FormInput label="Email" />
                        <FormInput label="Password" />
                        <FormInput label="Confirmar Password" />
                        <FormCheckbox />
                        <div className="flex justify-between items-center space-x-2">
                            <FormButton content="Actualizar" type="button"
                                btnStyle="bg-primary hover:bg-blue-700 text-white" />
                            <FormButton content="Cancelar" type="reset"
                                btnStyle="login-btn bg-red-500 hover:bg-red-700 text-white" />
                        </div>
                    </form>
                </div>
            }
        </div>
    );
}

export default LoginForm;
