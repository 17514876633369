import { Link } from "react-router-dom";

const MemberPreview = ({ title, members }) => {
    return (
        <div className="space-y-2">
            <h2 className="font-bold">{ title }</h2>
            <div className="inline-flex flex-col space-y-1">
                { members.map(member => (
                    <span key={member._id}>
                        <Link target="_blank" to={`/citizens/details/${member._id}`} className="text-indigo-700 hover:underline">
                            { `${member.first_name} ${member.last_name}` }
                        </Link>
                    </span>
                ))}
            </div>
        </div>
    );
}
 
export default MemberPreview;