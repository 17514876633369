import useFetch from "./useFetch";
import {BASE_URL} from "../utils";
import Header from "./Header";

const Dashboard = () => {
    const {isLoading, error, data} = useFetch(`${BASE_URL}/api/properties/statistics`);
    return (
        <>
            <Header />
            <div className="mx-20 mt-24 space-y-6">
                <div className="flex justify-between items-center border-b border-gray-200 pb-4 ">
                    <h1 className="font-bold text-3xl text-gray-900">Estatísticas</h1>
                </div>

                {error && <div className="text-2xl font-bold text-red-500">Houve um erro no sistema...</div>}

                {isLoading && <div className="text-2xl font-bold font-medium">Carregando os dados...</div>}

                {
                    data &&
                    <div className="grid grid-cols-3 gap-6 font-extrabold text-white text-2xl">
                        <div className="bg-black h-44 p-6 space-y-8 rounded">
                            <h2>Total de Propriedades</h2>
                            <h1 className="text-6xl text-center">{data.total}</h1>
                        </div>

                        <div className="bg-green-400 h-44 p-6 space-y-8 rounded">
                            <h2>Normais</h2>
                            <h1 className="text-6xl text-center">{data.main}</h1>
                        </div>

                        <div className="bg-purple-400 h-44 p-6 space-y-8 rounded">
                            <h2>Arrendadas</h2>
                            <h1 className="text-6xl text-center">{data.rented}</h1>
                        </div>

                        <div className="bg-primary h-44 p-6 space-y-8 rounded">
                            <h2>Sub-Arrendadas</h2>
                            <h1 className="text-6xl text-center">{data.subRented}</h1>
                        </div>

                        <div className="bg-primary h-44 p-6 space-y-8 rounded">
                            <h2>Condomínios</h2>
                            <h1 className="text-6xl text-center">{data.condominium}</h1>
                        </div>

                        <div className="bg-primary h-44 p-6 space-y-8 rounded">
                            <h2>Habitadas</h2>
                            <h1 className="text-6xl text-center">{data.inhabited}</h1>
                        </div>

                        <div className="bg-blue-400 h-44 p-6 space-y-8 rounded">
                            <h2>Desabitadas</h2>
                            <h1 className="text-6xl text-center">{data.uninhabited}</h1>
                        </div>

                        <div className="bg-red-400 h-44 p-6 space-y-8 rounded">
                            <h2>Abandonadas</h2>
                            <h1 className="text-6xl text-center">{data.abandoned}</h1>
                        </div>

                        <div className="bg-yellow-600 h-44 p-6 space-y-8 rounded">
                            <h2>Em Construção</h2>
                            <h1 className="text-6xl text-center">{data.underConstruction}</h1>
                        </div>

                        <div className="bg-green-400 h-44 p-6 space-y-8 rounded">
                            <h2>Cidadãos</h2>
                            <h1 className="text-6xl text-center">{data.citizens}</h1>
                        </div>
                    </div>
                }
            </div>
        </>
    );
}

export default Dashboard
