import { Link, useHistory, useParams } from 'react-router-dom';
import FormInput from '../../login/FormInput';
import Header from "../../Header";
import { useEffect, useState } from 'react';
import { ArrowRightIcon, BanIcon, HomeIcon, TrashIcon, XIcon } from '@heroicons/react/solid';
import useFetch from '../../useFetch';
import { BASE_URL } from '../../../utils';

const UpdateRentedProperty = () => {
    const { id } = useParams();
    const history = useHistory();

    // Get previous property information.
    const { isLoading, error, data: oldProperty } = useFetch(BASE_URL + `/api/properties/${id}`);
    // Set form to previous information
    useEffect(() => {
        if (oldProperty) 
            handleClear();
    }, [oldProperty]);

    // State for the previous information of the property
    const [designation, setDesignation] = useState('');
    const [building, setBuilding] = useState('');
    const [floor, setFloor] = useState('');
    const [door, setDoor] = useState('');
    const propertyType = 'Sub Arrendada';
    const [livingState, setLivingState] = useState('Habitada');
    const [propertyFunction, setPropertyFunction] = useState('');
    const [observation, setObservation] = useState('');

    const [formSubmited, setFormSubmited] = useState(false);
    const [submissionError, setSubmissionFailed] = useState(false);
    const [processingSubmission, setProcessingSubmission] = useState(false);

    // Once the form is submited successfully this state will contain the ID of
    // the new property saved on DB, in order to redirect to the property
    // details page.
    const [updatedProperty, setUpdatedProperty] = useState(null);

    function handleSubmit(e) {
        e.preventDefault();
        setFormSubmited(true);

        const [ownersToAdd, ownersToRemove] = getCitizens('Proprietário');
        const [keepersToAdd, keepersToRemove] = getCitizens('Responsável');
        const [membersToAdd, membersToRemove] = getCitizens('Membro');

        const property = {
            property_details: {
                designation,
                building,
                floor,
                door,
                observation,

                property_type: propertyType,
                living_state: livingState,
                property_function: propertyFunction
            },
            property_owners: {
                add: ownersToAdd,
                remove: ownersToRemove
            },
            property_keepers: {
                add: keepersToAdd,
                remove: keepersToRemove
            },
            property_members: {
                add: membersToAdd,
                remove: membersToRemove
            }
        };

        fetch(`${BASE_URL}/api/properties/${id}`, {
            headers: { 'Content-Type': 'application/json' },
            method: 'PUT',
            body: JSON.stringify(property)
        })
            .then(response => {
                if (!response.ok) throw Error();
                return response.json();
            })
            .then(result => {
                setSubmissionFailed(false);
                setProcessingSubmission(false);

                // Get new registered property ID from database
                // to redirect to property details page.
                // This first show a success message
                setUpdatedProperty(result._id);
            })
            .catch(_err => setSubmissionFailed(true));
        
        function getCitizens(type) {
            const add = citizensToAdd
                .filter(citizen => citizen.type === type)
                .map(owner => owner.id);

            const remove = citizensToDelete
                .filter(citizen => citizen.type === type)
                .map(citizen => citizen.id);

            return [add, remove];
        }
    }

    // State for citizens
    const [citizensUI, setCitizensUI] = useState([]);
    const [citizensToAdd, setCitizensToAdd] = useState([]);
    const [citizensToDelete, setCitizensToDelete] = useState([]);

    function handleAddCitizen(event) {
        const newCitizen = { 
            name: event.target.dataset.name,
            type: 'Membro',
            id: event.target.dataset.id
        };

        // Avoid duplicates
        for (const citizen of citizensUI) 
            if (citizen.id === newCitizen.id)
                return;

        for (const citizen of citizensToDelete) 
            if (citizen.id === newCitizen.id)
                return;

        // Show citizens on UI.
        let newCitizens = citizensUI.filter(citizen => 
            citizen.id !== newCitizen.id);
        setCitizensUI([...newCitizens, newCitizen]);

        // Add citizens to array that will be sent to database.
        newCitizens = citizensToAdd.filter(citizen => 
            citizen.id !== newCitizen.id);
        setCitizensToAdd([...newCitizens, newCitizen]);
    }

    function handleDeleteCitizen(citizenID, typeOfCitizen) {
        // Remove citizen from UI
        handleRemoveCitizen(citizenID);

        // Add citizen to deletion list
        const citizens = citizensToDelete.filter(citizen => 
            citizen.id !== citizenID);
        setCitizensToDelete([...citizens, { 
            id: citizenID,
            type: typeOfCitizen
        }]);
    }

    function handleRemoveCitizen(citizenId) {
        // Remove from UI
        let newCitizens = citizensUI.filter(citizen => 
            citizen.id !== citizenId);
        setCitizensUI(newCitizens);

        // Remove from list of members to be saved on database
        newCitizens = citizensToAdd.filter(citizen => 
            citizen.id !== citizenId);
        setCitizensToAdd([...newCitizens]);
    }

    function handleOwnership(event) {
        const citizens = citizensToAdd;
        citizens.forEach(citizen => {
            if (citizen.id === event.target.dataset.id)
                citizen.type = event.target.value;
        });
        setCitizensToAdd([...citizens]);
    }

    function handleClear() {
        setDesignation(oldProperty.designation);
        setBuilding(oldProperty.building);
        setFloor(oldProperty.floor);
        setDoor(oldProperty.door);
        setLivingState(oldProperty.living_state);
        setPropertyFunction(oldProperty.property_function);
        setObservation(oldProperty.observation);

        // Show old citizens on UI
        showCitizens();

        function showCitizens() {
            setCitizensToDelete([]);
            setCitizensToAdd([]);
            setCitizensUI([]);

            const owners = getMembers(oldProperty.owners, 'Proprietário');
            const keepers = getMembers(oldProperty.keepers, 'Responsável');
            const members = getMembers(oldProperty.members, 'Membro');

            setCitizensUI([...owners, ...keepers, ...members]);
        }

        function getMembers(members, typeOfMembers) {
            const temp = [];
            if (members) {
                members.forEach(member => {
                    temp.push({
                        old: true,
                        name: member.first_name + ' ' + member.last_name,
                        type: typeOfMembers,
                        id: member._id
                    });
                });
            }
            return temp;
        }
    }

    // State for search
    const [citizenToSearch, setCitizenToSearch] = useState('');
    const [searchedCitizens, setSearchedCitizens] = useState([]);
    const [searchError, setSearchError] = useState(false);

    function handleSearch(event) {
        event.preventDefault();

        fetch(BASE_URL + `/api/citizens/search/${citizenToSearch}`)
            .then(response => {
                if (!response.ok)
                    throw new Error();

                return response.json();
            })
            .then(items => {
                if (items.length > 0) {
                    setSearchError(false);
                    setSearchedCitizens([...items]);
                } else {
                    throw new Error();
                }
            })
            .catch(_ => {
                setSearchedCitizens([]);
                setSearchError(true);
            });
    }

    return (
        <div>
            <Header />
            <div className="mx-20 mt-24 pb-16">
                {
                    error &&
                    <div className="text-red-500">
                        Houve um erro no sistema
                    </div>
                }
                {
                    isLoading &&
                    <div className="font-medium">Carregando os dados...</div>
                }
                {
                    // Show a processing message
                    processingSubmission &&
                    <div className="p-8 space-y-4 w-1/2 mx-auto flex flex-col justify-center items-center bg-white shadow-md hover:shadow-lg rounded-md">
                        <h2 className="text-3xl font-extrabold text-green-500">
                            Processando o registro...
                        </h2>
                    </div>
                }
                {
                    // Show an error if the form submission had an error
                    submissionError &&
                    <div className="p-8 space-y-4 w-1/2 mx-auto flex flex-col justify-center items-center bg-white shadow-md hover:shadow-lg rounded-md">
                        <div className="text-center space-y-5">
                            <h2 className="text-3xl font-bold text-red-500">
                                Occorreu um erro no registro
                            </h2>
                            <p className="text-lg font-medium">
                                Por favor, tente novamente clicando no botao abaixo
                            </p>
                        </div>
                        <button className="btn btn-primary" onClick={() => { setProcessingSubmission(false); setSubmissionFailed(false); setFormSubmited(false) }}>
                            Tentar Novamente
                        </button>
                    </div>
                }
                {
                    // Show a success message
                    updatedProperty &&
                    <div className="p-8 space-y-4 w-1/2 mx-auto flex flex-col justify-center items-center bg-white shadow-md hover:shadow-lg rounded-md">
                        <div className="text-center space-y-5">
                            <h2 className="text-3xl font-bold text-green-500">
                                Propriedade registrada com sucesso
                            </h2>
                            <p className="text-lg font-medium">
                                Veja os dados e informações da propriedade
                            </p>
                        </div>
                        <button className="btn btn-primary" onClick={() => history.push(`/rented-properties/details/${updatedProperty}`)}>
                            <span>Ver Informações</span>
                            <ArrowRightIcon className="w-6 h-6"/>
                        </button>
                    </div>
                }
                {
                    !formSubmited &&
                    <div className="flex justify-between items-start">
                        <form onSubmit={handleSubmit} className="w-1/2 mx-auto bg-white shadow-md rounded-md space-y-2">
                            <h1 className="px-8 py-4 border-b border-gray-100 flex justify-center items-center text-center text-2xl font-extrabold text-primary">
                                Actualizando Propriedade
                            </h1>

                            {/* INFORMATION OF THE PROPERTY TO SUBMIT */}
                            <div className="px-8 py-2 grid grid-cols-2 gap-8">
                                <FormInput label="Designação"value={designation} onInput={e => setDesignation(e.target.value)}/>
                                <FormInput label="Nome do Edifício (Prédio)" value={building} onInput={e => setBuilding(e.target.value)}/>
                                <FormInput label="Andar" value={floor} onInput={e => setFloor(String(e.target.value).toUpperCase())}/>
                                <FormInput label="Porta" value={door} onInput={e => setDoor(String(e.target.value).toUpperCase())}/>
                                <FormInput label="Tipo de Propriedade" value={propertyType} onChange={() => {}} disabled={'disabled'} />
                                <div className="flex flex-col space-y-1">
                                    <label className="login-input-label">Estado da Propriedade</label>
                                    <select className="login-input" defaultValue={livingState} onChange={e => setLivingState(e.target.value)}>
                                        <option value="Habitada">Habitada</option>
                                        <option value="Desabitada">Desabitada</option>
                                        <option value="Abandonada">Abandonada</option>
                                        <option value="Em Construção">Em Construção</option>
                                    </select>
                                </div>
                                <FormInput label="Finalidade" value={propertyFunction} onInput={e => setPropertyFunction(e.target.value)}/>
                                <FormInput label="Observação" value={observation} onInput={e => setObservation(e.target.value)}/>
                            </div>

                            {/* MEMBERS OF THE PROPERTY (UI MEMBERS) */}
                            { 
                                citizensUI.length > 0 &&
                                <div className="space-y-3">
                                    <h3 className="px-8 py-4 border-b border-t border-gray-100 text-xl font-bold text-primary">
                                        Membros da Propriedade
                                    </h3>

                                    <div className="px-8 space-y-3">
                                        {
                                            citizensUI.map(citizen => (
                                                <div className="flex justify-between items-center" key={citizen.id}>
                                                    <Link target="_blank" to={`/citizens/details/${citizen.id}`} className="font-medium text-indigo-700 hover:underline">
                                                        {citizen.name}
                                                    </Link>

                                                    { 
                                                        citizen.old ? 
                                                        <div className="flex items-center space-x-4">
                                                            <p className="italic font-medium">{ citizen.type  }</p> 

                                                            <button type="button" onClick={() => handleDeleteCitizen(citizen.id, citizen.type)} className="p-1 rounded bg-red-500 hover:bg-red-700 font-medium text-white" title="Apagar membro?">
                                                                <TrashIcon className="w-6 h-6" />
                                                            </button>
                                                        </div>
                                                        :
                                                        <div className="flex items-center space-x-4">
                                                            <select className="border border-gray-400 p-1 text-sm" defaultValue='Membro' onChange={handleOwnership} data-id={citizen.id}>
                                                                <option value="Proprietário">Proprietário</option>
                                                                <option value="Responsável">Responsável</option>
                                                                <option value="Membro">Membro</option>
                                                            </select>

                                                            <button type="button" onClick={() => handleRemoveCitizen(citizen.id)} className="px-2 py-0.5 rounded bg-red-500 hover:bg-red-700 text-sm text-white" title="Remover membro da lista?">
                                                                <XIcon className="w-6 h-6"/>
                                                            </button>
                                                        </div>
                                                    }

                                                </div>
                                            ))
                                        }
                                    </div>
                                </div>
                            }

                            {/* SUBMIT OR RESET FORM */}
                            <div className="border-t-2 py-4 border-gray-200 flex justify-center items-center space-x-6">
                                <button onClick={handleSubmit} type="button" className="btn btn-primary">
                                    <HomeIcon className="w-6 h-6" />
                                    <span>Registar</span>
                                </button>

                                <button onClick={handleClear} type="button" className="btn btn-danger">
                                    <BanIcon className="w-6 h-6" />
                                    <span>Limpar</span>
                                </button>
                            </div>
                        </form>

                        {/*  SEARCH CITIZENS MENU */}
                        <div className="w-64 space-y-2">
                            <div className="bg-white shadow p-2 space-y-2">
                                <div>
                                    <h3 className="text-center text-sm bg-primary p-2 rounded-tl-md rounded-tr-md text-white font-bold">
                                        Membros da propriedade
                                    </h3>
                                    <form onSubmit={handleSearch}>
                                        <input value={citizenToSearch} onInput={event => setCitizenToSearch(event.target.value)} type="search" placeholder="Pesquisar nome" className="w-full bg-white p-1.5 rounded-bl-md rounded-br-md border-l-2 border-r-2 border-b-2 border-gray-300 hover:border-primary focus:border-primary outline-none transition duration-300 ease-out" />
                                    </form>
                                </div>
                                <div className="h-52 overflow-y-auto">
                                    {
                                        searchedCitizens.length > 0 &&
                                        <div className="text-sm right-0 w-full bg-white p-2 text-gray-600">
                                            {
                                                searchedCitizens.map(citizen => 
                                                    <button onClick={handleAddCitizen} data-name={`${citizen.first_name} ${citizen.last_name}`} data-id={citizen._id} key={citizen._id} type="button" className="w-full p-1 text-left hover:bg-indigo-200 italic font-medium">
                                                        {`${citizen.first_name} ${citizen.last_name}`}
                                                    </button>
                                                )
                                            }
                                        </div>
                                    }
                                    {
                                        searchError &&
                                        <div className="w-64 text-red-500 font-bold">
                                            Nehum resultado encontrado
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                }
            </div>
        </div>
    );
}
 
export default UpdateRentedProperty;
