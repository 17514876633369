import { BanIcon, HomeIcon } from '@heroicons/react/solid';
import { useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { BASE_URL } from '../../utils';
import Header from '../Header';
import FormInput from '../login/FormInput';
import useFetch from '../useFetch';

const UpdateCitizen = () => {
    const history = useHistory();
    const { id } = useParams();
    const { isLoading, error: failed, data: oldCitizen} = useFetch(`${BASE_URL}/api/citizens/${id}`);

    const [error, setError] = useState(false);
    const [success, setSuccess] = useState(false);
    const [notSubmited, setNotSubmited] = useState(true);

    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [birthdate, setBirthDate] = useState(Date.now());
    const [provenance, setProvenance] = useState('');
    const [nationalID, setNationalID] = useState('');
    const [occupation, setOccupation] = useState('');
    const [occupationLocation, setOccupationLocation] = useState('');
    const [occupationContact, setOccupationContact] = useState('');
    const [firstContact, setFirstContact] = useState('');
    const [secondContact, setSecondContact] = useState('');
    const [thirdContact, setThirdContact] = useState('');
    const [observation, setObservation] = useState('');

    function handleSubmit(e) {
        e.preventDefault();

        const citizenInfo = {
            first_name: firstName,
            last_name: lastName,
            birth_date: birthdate,
            provenance: provenance,
            national_identification: nationalID,
            occupation: occupation,
            occupation_location: occupationLocation,
            occupation_contact: occupationContact,
            contacts: [],
            observation: observation
        };

        // Prevent pushing contacts that are null.
        if (firstContact) citizenInfo.contacts.push(firstContact);
        if (secondContact) citizenInfo.contacts.push(secondContact);
        if (thirdContact) citizenInfo.contacts.push(thirdContact);

        fetch(`${BASE_URL}/api/citizens/${id}`, {
                headers: { 'Content-Type': 'application/json' },
                method: 'PUT',
                body: JSON.stringify(citizenInfo)
        })
            .then(res => {
                if (!res.ok) throw Error();
                return res.json()
            })
            .then(res => {
                // Show Success Message
                setSuccess(true);
                setNotSubmited(false);
            })
            .catch(err => {
                setError(true);
                setSuccess(false);
                setNotSubmited(false);
            });
    }

    // Get previous citizen information from database.
    useEffect(() => {
        if (oldCitizen)
            handleClear();
    }, [oldCitizen]);

    function handleClear() {
        setFirstName(oldCitizen.first_name);
        setLastName(oldCitizen.last_name);
        setBirthDate(oldCitizen.birth_date);
        setProvenance(oldCitizen.provenance);
        setNationalID(oldCitizen.national_identification);
        setOccupation(oldCitizen.occupation);
        setOccupationLocation(oldCitizen.occupation_location);
        setOccupationContact(oldCitizen.occupation_contact);

        setFirstContact('');
        if (oldCitizen.contacts && oldCitizen.contacts[0] !== null)
            setFirstContact(oldCitizen.contacts[0]);

        setSecondContact('');
        if (oldCitizen.contacts && oldCitizen.contacts[1] !== null)
            setSecondContact(oldCitizen.contacts[1]);

        setThirdContact('');
        if (oldCitizen.contacts && oldCitizen.contacts[2] !== null)
            setThirdContact(oldCitizen.contacts[2]);

        if (oldCitizen.observation)
            setObservation(oldCitizen.observation);
    }

    function handleFormSuccess(){
        history.push(`/citizens/details/${success}`);
    }

    return (
        <>
            <Header />
            <div className="mx-20 mt-24 space-y-10">
                { isLoading && <div className="text-lg font-medium">Carrengado informações...</div> }

                {
                    success &&
                    <div className="w-1/2 mx-auto flex flex-col justify-center items-center space-y-8 p-8 bg-white shadow-md rounded-md">
                        <div className="text-center space-y-4">
                            <h2 className="text-2xl font-bold text-green-500">
                                Cidadão registado com sucesso
                            </h2>
                            <p className="text-lg">Click abaixo para confirmar os dados</p>
                        </div>

                        <button className="btn btn-success" onClick={handleFormSuccess}>
                            Confirmar Dados
                        </button>
                    </div>
                }

                {
                    (error || failed) &&
                    <div className="w-1/2 mx-auto flex flex-col justify-center items-center bg-white rounded-md">
                        <div className="text-center p-8 space-y-5">
                            <h2 className="text-2xl font-bold text-red-500">
                                Ooops parece que houve um erro
                            </h2>
                            <p className="text-lg">Tente novamente, caso o problema persista contacte o desenvolvedor</p>
                        </div>
                        <div className="p-8">
                            <button className="btn btn-primary" onClick={() => { setError(false); setNotSubmited(true); }}>
                                Tentar Novamente
                            </button>
                        </div>
                    </div>
                }

                {
                    oldCitizen && notSubmited &&
                    <form className="w-1/2 mx-auto bg-white shadow hover:shadow-md rounded-md space-y-2">
                        <h1 className="px-8 py-4 border-b border-gray-100 flex justify-center items-center text-center text-2xl font-extrabold text-primary">
                            Actualizando Dados
                        </h1>

                        <div className="px-8 py-2 grid grid-cols-2 gap-6">
                            <FormInput label="Nome" value={firstName} onInput={(e) => setFirstName(e.target.value)}/>
                            <FormInput label="Apelido" value={lastName} onInput={(e) => setLastName(e.target.value)}/>
                            <FormInput label="Data de Nascimento" type="date" value={birthdate} onInput={(e) => setBirthDate(e.target.value)}/>
                            <FormInput label="Proveniência" value={provenance} onInput={(e) => setProvenance(e.target.value)}/>
                            <FormInput label="Bilhete de Identidate" value={nationalID} onInput={(e) => setNationalID(e.target.value)}/>
                            <FormInput label="Ocupação" value={occupation} onInput={(e) => setOccupation(e.target.value)}/>
                            <FormInput label="Local da Ocupação" value={occupationLocation} onInput={(e) => setOccupationLocation(e.target.value)}/>
                            <FormInput label="Contacto da Ocupação" value={occupationContact} onInput={(e) => setOccupationContact(e.target.value)}/>
                            <FormInput label="Contacto Pessoal" value={firstContact} onInput={(e) => setFirstContact(e.target.value)}/>
                            <FormInput label="Contacto Alternativo 1" value={secondContact} onInput={(e) => setSecondContact(e.target.value)}/>
                            <FormInput label="Contacto Alternativo 2" value={thirdContact} onInput={(e) => setThirdContact(e.target.value)}/>
                            <FormInput label="Obeservação" value={observation} onInput={(e) => setObservation(e.target.value)}/>
                        </div>

                        <div className="border-t py-4 border-gray-100 flex justify-center items-center space-x-6">
                            <button onClick={handleSubmit} type="button" className="btn btn-primary">
                                <HomeIcon className="w-6 h-6" />
                                <span>Actualizar</span>
                            </button>

                            <button onClick={handleClear} type="button" className="btn btn-danger">
                                <BanIcon className="w-6 h-6" />
                                <span>Limpar</span>
                            </button>
                        </div>
                    </form>
                }
            </div>
        </>
    );
}

export default UpdateCitizen;