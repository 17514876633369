import logo from '../../inscrito-logo.svg';

const FormCaption = ({ content }) => {
    return (
        <div className="flex flex-col justify-center items-center space-y-4">
            <img src={logo} className="w-20 text-primary" />
            <h1 className="font-semibold text-xl">{ content }</h1>
        </div>
    );
}
 
export default FormCaption;