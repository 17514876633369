import {useState} from 'react';
import {BrowserRouter as Router, Switch, Route, Redirect} from 'react-router-dom';

import CreateProperty from './components/property/CreateProperty';
import PropertyDetails from './components/property/PropertyDetails';
import UpdateProperty from './components/property/UpdateProperty';
import Properties from './components/property/Properties';

import CreateRentedProperty from './components/property/rented property/CreateRentedProperty';
import UpdateRentedProperty from './components/property/rented property/UpdateRentedProperty';
import RentedProperties from './components/property/rented property/RentedProperties';

import CreateCitizen from './components/citizen/CreateCitizen';
import CitizenDetails from './components/citizen/CitizenDetails';
import UpdateCitizen from './components/citizen/UpdateCitizen';
import Citizens from './components/citizen/Citizens';

import CreateUser from './components/user/CreateUser';
import UserDetails from './components/user/UserDetails';
import UpdateUser from './components/user/UpdateUser';
import Users from './components/user/Users';

import LoginForm from './components/login/LoginForm';
import Dashboard from './components/Dashboard';
import RentedPropertyDetails from './components/property/rented property/RentedPropertyDetails';

const App = () => {
    const [loggedIn, setLoggedIn] = useState(false);

    return (
               <Router className="text-gray-900">
            <Switch>
                <Route exact path="/dashboard">
                    <Dashboard />
                </Route>

                {/* Property Routes */}
                <Route exact path="/properties/create">
                    <CreateProperty />
                </Route>
                <Route exact path="/properties/details/:id">
                    <PropertyDetails />
                </Route>
                <Route exact path="/properties/update/:id">
                    <UpdateProperty />
                </Route>
                <Route path="/properties">
                    <Properties />
                </Route>

                {/* Rented Properties Routes */}
                <Route exact path="/rented-properties/create">
                    <CreateRentedProperty />
                </Route>
                <Route exact path="/rented-properties/details/:id">
                    <RentedPropertyDetails />
                </Route>
                <Route exact path="/rented-properties/update/:id">
                    <UpdateRentedProperty />
                </Route>
                <Route path="/rented-properties">
                    <RentedProperties />
                </Route>

                {/* Citizen Routes */}
                <Route exact path="/citizens/create">
                    <CreateCitizen />
                </Route>
                <Route exact path="/citizens/details/:id">
                    <CitizenDetails />
                </Route>
                <Route exact path="/citizens/update/:id">
                    <UpdateCitizen />
                </Route>
                <Route exact path="/citizens">
                    <Citizens />
                </Route>

                {/* User Routes */}
                <Route exact path="/users/create">
                    <CreateUser />
                </Route>
                <Route exact path="/users/details/:id">
                    <UserDetails />
                </Route>
                <Route exact path="/users/update/:id">
                    <UpdateUser />
                </Route>
                <Route exact path="/users">
                    <Users />
                </Route>

                {/* Login Route */}
                <Route path="/login">
                    <LoginForm />
                </Route>

                {/* System entry point */}
                <Route exact path="/">
                    {loggedIn ? <Redirect to="/dashboard" /> : <LoginForm />}
                </Route>
            </Switch>
            <div className="py-16 text-center text-gray-500">
                &copy; Copyright 2021 Inscrito.
            </div>
        </Router>
    );
}

export default App;
